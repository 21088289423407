import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

// Mockup de dados para os gráficos
const data1 = [
  { name: 'Jan', valor: 3.5 },
  { name: 'Fev', valor: 4.0 },
  { name: 'Mar', valor: 4.2 },
  { name: 'Abr', valor: 4.3 },
  { name: 'Mai', valor: 4.6 },
  { name: 'Jun', valor: 4.7 },
];

const data2 = [
  { name: 'Distribuidor A', valor: 4.2 },
  { name: 'Distribuidor B', valor: 4.5 },
  { name: 'Distribuidor C', valor: 4.1 },
];

const Dashboard: React.FC = () => {
  return (
    <div style={{
      padding: '20px',
      fontFamily: 'Arial, sans-serif',
      backgroundColor: '#f4f7fc',
      minHeight: '100vh',
    }}>
      <h1 style={{ textAlign: 'center', color: '#333', marginBottom: '30px' }}>Cotações de Combustível</h1>
      
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '30px' }}>
        <div style={{ width: '100%', maxWidth: '1200px' }}>
          <h2 style={{ textAlign: 'center', color: '#333' }}>Preço Médio por Mês</h2>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={data1}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="valor" stroke="#8884d8" />
            </LineChart>
          </ResponsiveContainer>
        </div>
        
        <div style={{ width: '100%', maxWidth: '1200px' }}>
          <h2 style={{ textAlign: 'center', color: '#333' }}>Comparação de Distribuidores</h2>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={data2}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="valor" stroke="#82ca9d" />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
      
      <div style={{
        marginTop: '50px',
        padding: '20px',
        borderRadius: '8px',
        border: '1px solid #ddd',
        backgroundColor: '#fff',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      }}>
        <h3 style={{ textAlign: 'center', color: '#333' }}>Informações de Cotações</h3>
        <div style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gap: '20px',
          alignItems: 'center',
          justifyItems: 'center',
        }}>
          <div>
            <p><strong>Distribuidor A:</strong></p>
            <p style={{ fontSize: '18px', fontWeight: 'bold', color: '#4CAF50' }}>R$ 4.20/litro</p>
          </div>
          <div>
            <p><strong>Distribuidor B:</strong></p>
            <p style={{ fontSize: '18px', fontWeight: 'bold', color: '#FF9800' }}>R$ 4.50/litro</p>
          </div>
          <div>
            <p><strong>Distribuidor C:</strong></p>
            <p style={{ fontSize: '18px', fontWeight: 'bold', color: '#F44336' }}>R$ 4.10/litro</p>
          </div>
          <div>
            <p><strong>Preço Médio Atual:</strong></p>
            <p style={{ fontSize: '18px', fontWeight: 'bold', color: '#2196F3' }}>R$ 4.25/litro</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
