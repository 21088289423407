export const dataGasolina = [
  { date: "fev/2025", distribuidora_A: 5.89, distribuidora_B: 5.87 },
  { date: "02/01/2025", distribuidora_A: 5.92, distribuidora_B: 6.00 },
  { date: "03/01/2025", distribuidora_A: 5.95, distribuidora_B: 5.93 },
  { date: "04/01/2025", distribuidora_A: 5.97, distribuidora_B: 6.00 },
  { date: "05/01/2025", distribuidora_A: 6.00, distribuidora_B: 5.98 },
  { date: "06/01/2025", distribuidora_A: 6.02, distribuidora_B: 5.95 },
  { date: "07/01/2025", distribuidora_A: 6.04, distribuidora_B: 5.98 },
  { date: "08/01/2025", distribuidora_A: 6.06, distribuidora_B: 6.01 },
  { date: "09/01/2025", distribuidora_A: 6.08, distribuidora_B: 6.03 },
  { date: "10/01/2025", distribuidora_A: 6.10, distribuidora_B: 6.05 },
  { date: "11/01/2025", distribuidora_A: 6.12, distribuidora_B: 6.07 },
  { date: "12/01/2025", distribuidora_A: 6.14, distribuidora_B: 6.09 },
  { date: "13/01/2025", distribuidora_A: 6.16, distribuidora_B: 6.10 },
  { date: "14/01/2025", distribuidora_A: 6.18, distribuidora_B: 6.12 },
  { date: "15/01/2025", distribuidora_A: 6.20, distribuidora_B: 6.13 },
  { date: "16/01/2025", distribuidora_A: 6.22, distribuidora_B: 6.15 },
  { date: "17/01/2025", distribuidora_A: 6.24, distribuidora_B: 6.17 },
  { date: "18/01/2025", distribuidora_A: 6.26, distribuidora_B: 6.19 },
  { date: "19/01/2025", distribuidora_A: 6.28, distribuidora_B: 6.21 },
  { date: "20/01/2025", distribuidora_A: 6.30, distribuidora_B: 6.23 },
  { date: "21/01/2025", distribuidora_A: 6.32, distribuidora_B: 6.25 },
  { date: "22/01/2025", distribuidora_A: 6.34, distribuidora_B: 6.27 },
  { date: "23/01/2025", distribuidora_A: 6.36, distribuidora_B: 6.29 },
  { date: "24/01/2025", distribuidora_A: 6.38, distribuidora_B: 6.31 },
  { date: "25/01/2025", distribuidora_A: 6.40, distribuidora_B: 6.33 }
];

export const tableData = [
  { key: "1", produto: "Gasolina", distribuidora: "Distribuidora A", aquisicao: 5.89, mediaMes: 5.92, revenda: 6.50 },
  { key: "2", produto: "Gasolina", distribuidora: "Distribuidora B", aquisicao: 5.87, mediaMes: 5.90, revenda: 6.48 },
  { key: "3", produto: "Etanol", distribuidora: "Distribuidora C", aquisicao: 3.79, mediaMes: 3.85, revenda: 4.20 },
  { key: "4", produto: "Etanol", distribuidora: "Distribuidora D", aquisicao: 3.75, mediaMes: 3.80, revenda: 4.15 },
];

export const columns = [
  { title: "Nome Produto", dataIndex: "produto", key: "produto" },
  { title: "Distribuidora", dataIndex: "distribuidora", key: "distribuidora" },
  { title: "Preço de Aquisição (R$)", dataIndex: "aquisicao", key: "aquisicao" },
  { title: "Valor Médio Mês (R$)", dataIndex: "mediaMes", key: "mediaMes" },
  { title: "Preço Médio Revenda (R$)", dataIndex: "revenda", key: "revenda" },
];