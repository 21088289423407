import * as Yup from "yup";

export interface IDocument {
  type: "cpf" | "cnpj" | "rg" | "ie";
  value: string;
}

export interface IContact {
  type: "email" | "mobile" | "landline";
  value: string;
  isPrimary: boolean;
}

export interface IAddress {
  type: "residential" | "commercial";
  postalCode: string;
  ibgeCode: string;
  street: string;
  neighborhood: string;
  city: string;
  uf: string;
  number: string;
  complement: string;
}

export interface IPropsValues {
  id?: string;
  type: "individual" | "legal_entity";
  legalName: string;
  tradeName?: string;
  addresses: IAddress[];
  contacts: IContact[];
  documents: IDocument[];
}

export const initialValues: IPropsValues = {
  id: "",
  type: "individual",
  legalName: "",
  tradeName: "",
  addresses: [
    {
      type: "residential",
      ibgeCode: "",
      street: "",
      number: "",
      complement: "",
      neighborhood: "",
      city: "",
      uf: "",
      postalCode: "",
    },
  ],
  contacts: [
    {
      type: "email",
      value: "",
      isPrimary: true,
    },
  ],
  documents: [
    {
      type: "cpf",
      value: "",
    },
  ],
};

export const RegisterSchema = Yup.object().shape({
  id: Yup.string(),
  type: Yup.string()
    .oneOf(["individual", "legal_entity"])
    .required("Tipo de pessoa é obrigatório"),
  legalName: Yup.string().required("Razão Social/Nome é obrigatório"),
  tradeName: Yup.string(), // Opcional
  addresses: Yup.array()
    .of(
      Yup.object().shape({
        ibgeCode: Yup.string().required("Código IBGE é obrigatório"),
        street: Yup.string().required("Endereço é obrigatório"),
        number: Yup.string().required("Número do endereço é obrigatório"),
        city: Yup.string(), // Opcional
        uf: Yup.string(), // Opcional
        complement: Yup.string(), // Opcional
        neighborhood: Yup.string().required("Bairro é obrigatório"),
        postalCode: Yup.string().required("CEP é obrigatório"),
        type: Yup.string()
          .oneOf(["residential", "commercial"])
          .required("Tipo de endereço é obrigatório"),
      })
    )
    .min(1, "Pelo menos um endereço é obrigatório"),
  contacts: Yup.array()
    .of(
      Yup.object().shape({
        type: Yup.string()
          .oneOf(["email", "landline", "mobile"])
          .required("Tipo de contato é obrigatório"),
        value: Yup.string().required("Valor do contato é obrigatório"),
        isPrimary: Yup.boolean().required(),
      })
    )
    .min(1, "Pelo menos um contato é obrigatório"),
  documents: Yup.array()
    .of(
      Yup.object().shape({
        type: Yup.string()
          .oneOf(["cpf", "cnpj", "rg", "ie"])
          .required("Tipo de documento é obrigatório"),
        value: Yup.string().required("Valor do documento é obrigatório"),
      })
    )
    .min(1, "Pelo menos um documento é obrigatório"),
});
