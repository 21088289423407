import React, { useCallback, useEffect, useState } from "react";
import {
  Col,
  Flex,
  Row,
  Space,
  Table,
  Typography,
  Modal,
  Form,
  Popconfirm,
  message,
} from "antd";
import type { TableProps } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";

import CustomInput from "../../../../components/Input";
import CustomButton from "../../../../components/Buttom";
import { colors } from "../../../../theme/colors";
import {
  getCategory,
  createCategory,
  updateCategory,
  deleteCategory,
} from "../../../../services/configurations";
import { IPropsResponseCategory } from "../../../../services/configurations/types";

const CategoryCustomers: React.FC = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [isEditing, setIsEditing] = useState(false);
  const [currentItem, setCurrentItem] = useState<IPropsResponseCategory | null>(
    null
  );

  const [loading, setLoading] = useState(false);
  const [categorys, setCategorys] = useState<IPropsResponseCategory[]>([]);

  const getCategorys = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getCategory();

      setCategorys(response);
    } catch (error) {
      const errorMessage =
        error instanceof Error
          ? error.message
          : "Ocorreu um erro ao tentar criar a categoria. Tente novamente mais tarde.";
      messageApi.open({
        type: "error",
        content: errorMessage,
      });
    }
    setLoading(false);
  }, [messageApi]);

  useEffect(() => {
    getCategorys();
  }, [getCategorys]);

  const handleCreate = async () => {
    try {
      const values = await form.validateFields();

      if (isEditing) {
        await updateCategory(values.name, values.anpCode, currentItem?.id);
      } else {
        await createCategory(values.name, values.anpCode);
      }

      form.resetFields();
      setIsModalVisible(false);
      setIsEditing(false);
      setCurrentItem(null);
      getCategorys();
    } catch (error) {
      console.error("Erro ao criar/editar Categoria:", error);

      const errorMessage =
        error instanceof Error
          ? error.message
          : "Ocorreu um erro ao tentar criar/editar a categoria. Tente novamente mais tarde.";

      messageApi.open({
        type: "error",
        content: errorMessage,
      });
    }
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
    setIsEditing(false);
    setCurrentItem(null);
  };

  const handleEdit = (data: IPropsResponseCategory) => {
    console.log(data);
    if (data) {
      setCurrentItem(data);
      setIsEditing(true);
      form.setFieldsValue(data);
      setIsModalVisible(true);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      await deleteCategory(id);
      message.success("Produto deletado com sucesso!");
      getCategorys();
    } catch (error) {
      const errorMessage =
        error instanceof Error
          ? error.message
          : "Erro ao deletar produto. Tente novamente.";

      message.error(errorMessage);
    }
  };

  const columns: TableProps<IPropsResponseCategory>["columns"] = [
    {
      title: "Categoria",
      dataIndex: "name",
      key: "name",
      render: (text: string) => <Typography.Text>{text}</Typography.Text>,
    },
    {
      title: "Cod ANP",
      dataIndex: "anpCode",
      key: "anpCode",
      render: (text: string) => <Typography.Text>{text}</Typography.Text>,
    },
    {
      title: "Ações",
      key: "action",
      align: "right",
      render: (_, record: IPropsResponseCategory) => (
        <Space size="middle">
          <EditOutlined
            style={{ color: colors.info, cursor: "pointer", fontSize: 18 }}
            onClick={() => handleEdit(record)}
          />
          <Popconfirm
            title="Tem certeza que deseja deletar este item?"
            onConfirm={() => handleDelete(record.id)}
            okText="Sim"
            cancelText="Não"
            placement="topRight"
          >
            <DeleteOutlined
              style={{ color: colors.error, cursor: "pointer", fontSize: 18 }}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Flex vertical>
      {contextHolder}
      <Typography.Title level={5} style={{ padding: 0 }}>
        Cadastro de Categorias de Clientes
      </Typography.Title>
      <Row
        align="middle"
        justify="space-between"
        style={{ width: "100%", flexWrap: "nowrap", marginBottom: 20 }}
      >
        <Col style={{ width: "50%" }}>
          <CustomInput
            style={{ width: "50%" }}
            title="Pesquisar"
            addonAfter={
              <SearchOutlined
                style={{ cursor: "pointer", color: colors.info }}
                onClick={() => console.log("Ícone de pesquisa clicado!")}
              />
            }
          />
        </Col>
        <CustomButton
          type="primary"
          onClick={() => {
            form.resetFields();
            setIsEditing(false);
            setCurrentItem(null);
            setIsModalVisible(true);
          }}
        >
          <PlusOutlined /> Criar novo
        </CustomButton>
      </Row>

      <Table columns={columns} dataSource={categorys} loading={loading} />

      <Modal
        title={isEditing ? "Editar Item" : "Criar novo"}
        visible={isModalVisible}
        onOk={handleCreate}
        onCancel={handleCancel}
        okText={isEditing ? "Salvar" : "Criar"}
        cancelText="Cancelar"
      >
        <Form form={form} layout="vertical">
          <Form.Item name="name" style={{ margin: 0 }}>
            <CustomInput placeholder="Digite uma descrição" title="Categoria" />
          </Form.Item>
          <Form.Item name="anpCode" style={{ margin: 0 }}>
            <CustomInput
              placeholder="Digite o Código da ANP"
              title="Código ANP"
            />
          </Form.Item>
        </Form>
      </Modal>
    </Flex>
  );
};

export default CategoryCustomers;
