export type MenuItem = {
  label: string;
  key: string;
  route?: string;
  children?: MenuItem[];
};

export const items: MenuItem[] = [
  { label: 'Dashboard', key: 'dashboard', route: '/' },
  { label: 'Cadastros', key: 'registrations',  children: [
    { label: 'Pessoa', key: 'posts', route: '/registrations' },
    { label: 'Categorias', key: 'categories', route: '/blog/categories' },
  ], },
  { label: 'Cotações', key: 'quotes', route: '/quotes' },
  { label: 'Relatórios', key: 'reports', route: '/reports' },
  { label: 'Blog', key: 'blog', route: '/blog' },
  { label: 'Minha conta', key: 'account', route: '/account' },
];