import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    /* font-family: 'Unimed Sans'; */
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    border-inline-end: 0;
  }

  body {
    background: ${({ theme }) => theme.colors.background};
    height: 100%;
  }

  img {
    display: block;
    max-width: 100%;
    user-select: none;
  }

  *::-webkit-scrollbar {
    width: 6px !important;
    height: 6px !important;
  }

  *::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.colors.background};
  }
  
  *::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.4);
  }

`

export default GlobalStyles;