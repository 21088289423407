import { DataType } from "./types";

export const data: DataType[] = [
  {
    id: "shell",
    name: "Shell",
    brand: "Shell",
  },
  {
    id: "br",
    name: "BR",
    brand: "BR",
  },
];