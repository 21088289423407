import React, { useEffect, useState } from "react";
import {
  Col,
  Flex,
  Row,
  Space,
  Table,
  Typography,
  Modal,
  Form,
  Popconfirm,
  Select,
  message,
} from "antd";
import type { TableProps } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";

import CustomInput from "../../../../components/Input";
import CustomButton from "../../../../components/Buttom";
import { colors } from "../../../../theme/colors";
import { IPropsResponseBrands } from "../../../../services/configurations/types";
import { createBrand, getBrands } from "../../../../services/configurations";

const { Option } = Select;

const BrandANP: React.FC = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [isEditing, setIsEditing] = useState(false);
  const [currentItem, setCurrentItem] = useState<IPropsResponseBrands | null>(
    null
  );

  const [loading, setLoading] = useState(false);
  const [brands, setBrands] = useState<IPropsResponseBrands[]>([]);

  const getBrand = async () => {
    setLoading(true);
    try {
      const response = await getBrands();

      setBrands(response);
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    getBrand();
  }, []);

  const handleCreate = () => {
    form.validateFields().then(async (values) => {
      try {
      } catch (error) {
        const errorMessage =
          error instanceof Error
            ? error.message
            : "Ocorreu um erro ao tentar criar/editar a categoria. Tente novamente mais tarde.";

        messageApi.open({
          type: "error",
          content: errorMessage,
        });
      }
      if (isEditing) {
        console.log("Edit item:", { ...currentItem, ...values });
      } else {
        await createBrand(values.name, values.type);
        await getBrand();
      }
      form.resetFields();
      setIsModalVisible(false);
      setIsEditing(false);
      setCurrentItem(null);
    });
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
    setIsEditing(false);
    setCurrentItem(null);
  };

  const handleEdit = (data: IPropsResponseBrands) => {
    if (data) {
      setCurrentItem(data);
      setIsEditing(true);
      form.setFieldsValue(data);
      setIsModalVisible(true);
    }
  };

  const handleDelete = (id: string) => {
    console.log(`Deleting item with id: ${id}`);
    // Adicione sua lógica de exclusão aqui
  };

  const columns: TableProps<IPropsResponseBrands>["columns"] = [
    {
      title: "Bandeira",
      dataIndex: "name",
      key: "name",
      render: (text: string) => <Typography.Text>{text}</Typography.Text>,
    },
    {
      // Tipo da bandeira (ex.: "Bandeirado", "Sem Bandeira").
      title: "Tipo",
      dataIndex: "type",
      key: "type",
      render: (text: string) => <Typography.Text>{text}</Typography.Text>,
    },
    {
      title: "Ações",
      key: "action",
      align: "right",
      render: (_, record: IPropsResponseBrands) => (
        <Space size="middle">
          <EditOutlined
            style={{ color: colors.info, cursor: "pointer", fontSize: 18 }}
            onClick={() => handleEdit(record)}
          />
          <Popconfirm
            title="Tem certeza que deseja deletar este item?"
            onConfirm={() => handleDelete(record.id)} // Chama a função de deletar
            okText="Sim"
            cancelText="Não"
            placement="topRight"
          >
            <DeleteOutlined
              style={{ color: colors.error, cursor: "pointer", fontSize: 18 }}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Flex vertical>
      {/* Header */}
      {contextHolder}

      <Typography.Title level={5} style={{ padding: 0 }}>
        Cadastro de Bandeiras
      </Typography.Title>

      <Row
        align="middle"
        justify="space-between"
        style={{
          width: "100%",
          flexWrap: "nowrap",
          marginTop: 0,
          marginBottom: 0,
        }}
      >
        <Col style={{ width: "50%" }}>
          <CustomInput
            style={{ width: "50%" }}
            title="Pesquisar"
            addonAfter={
              <SearchOutlined
                style={{ cursor: "pointer", color: colors.info }}
                onClick={() => console.log("Ícone de pesquisa clicado!")}
              />
            }
          />
        </Col>
        <CustomButton
          type="primary"
          onClick={() => {
            form.resetFields();
            setIsEditing(false);
            setCurrentItem(null);
            setIsModalVisible(true);
          }}
        >
          <PlusOutlined /> Criar novo
        </CustomButton>
      </Row>

      {/* Table */}
      <Table columns={columns} dataSource={brands} loading={loading} />

      {/* Modal */}
      <Modal
        title={isEditing ? "Editar Item" : "Criar novo"}
        visible={isModalVisible}
        onOk={handleCreate}
        onCancel={handleCancel}
        okText={isEditing ? "Salvar" : "Criar"}
        cancelText="Cancelar"
      >
        <Form form={form} layout="vertical">
          {/* Nome */}
          <Form.Item name="name" style={{ margin: 0 }}>
            <CustomInput placeholder="Digite uma descrição" title="Bandeira" />
          </Form.Item>
          <Form.Item
            name="type"
            label="Tipo"
            rules={[{ message: "Selecione o tipo" }]}
          >
            <Select placeholder="Selecione o tipo">
              <Option value="Bandeirado">Bandeirado</Option>
              <Option value="Sem Bandeira">Sem Bandeira</Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </Flex>
  );
};

export default BrandANP;
