import { Modal } from "antd";
import React from "react";
import CustomButton from "../Buttom";

interface IProps {
  open: boolean;
  setOpen: (value: boolean) => void;
}

const SubscribeModal: React.FC<IProps> = ({ open, setOpen }) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      title={<p>Loading Modal</p>}
      footer={
        <CustomButton type="primary" onClick={handleClose}>
          Confirmar
        </CustomButton>
      }
      open={open}
      onCancel={handleClose}
    >
      <p>Some contents...</p>
      <p>Some contents...</p>
      <p>Some contents...</p>
    </Modal>
  );
};

export default SubscribeModal;
