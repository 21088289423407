import React, { useCallback, useEffect, useState } from "react";
import {
  Col,
  Flex,
  Row,
  Space,
  Table,
  Typography,
  Modal,
  Form,
  Popconfirm,
  message,
} from "antd";
import type { TableProps } from "antd";
import {
  //EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";

import { ReadOnlyInput } from "./styles";
import CustomInput from "../../../../components/Input";
import CustomButton from "../../../../components/Buttom";
import { colors } from "../../../../theme/colors";
import {
  createMunicipality,
  getMunicipalities,
  updateMunicipality,
  deleteMunicipality,
} from "../../../../services/configurations";
import { IPropsResponseMunicipalities } from "../../../../services/configurations/types";
import { apiViaCep, IReturnApiCep } from "../../../../services/viaCep";
import { formatPostalCode } from "../../../../utils/masks";

const Municipalities: React.FC = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [isEditing, setIsEditing] = useState(false);
  const [currentItem, setCurrentItem] =
    useState<IPropsResponseMunicipalities | null>(null);
  const [loading, setLoading] = useState(false);
  const [municipalities, setMunicipalities] = useState<
    IPropsResponseMunicipalities[]
  >([]);
  const [filteredMunicipalities, setFilteredMunicipalities] = useState<
    IPropsResponseMunicipalities[]
  >([]);
  const [showAddressFields, setShowAddressFields] = useState(false);
  const [searchText, setSearchText] = useState("");

  const dataMunicipalities = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getMunicipalities();
      setMunicipalities(response);
      setFilteredMunicipalities(response);
    } catch (error) {
      const errorMessage =
        error instanceof Error
          ? error.message
          : "Ocorreu um erro ao tentar carregar os municípios. Tente novamente mais tarde.";
      messageApi.open({
        type: "error",
        content: errorMessage,
      });
    }
    setLoading(false);
  }, [messageApi]);

  useEffect(() => {
    dataMunicipalities();
  }, [dataMunicipalities]);

  const handleSearch = (value: string) => {
    setSearchText(value);
    if (value.trim() === "") {
      setFilteredMunicipalities(municipalities);
      return;
    }
    const lowerCaseValue = value.toLowerCase();
    const filteredData = municipalities.filter((municipality) => {
      return municipality.name.toLowerCase().includes(lowerCaseValue);
    });
    setFilteredMunicipalities(filteredData);
  };

  const handleGetAddressByCep = async (postalCode: string) => {
    const cleanPostalCode = postalCode.replace(/\D/g, "");
    setShowAddressFields(false);
    if (cleanPostalCode.length === 8) {
      try {
        const response = await apiViaCep.get<IReturnApiCep>(
          `${cleanPostalCode}/json`
        );
        if (response.data) {
          form.setFieldsValue({
            name: response.data.localidade,
            stateName: response.data.estado,
            ibgeCode: response.data.ibge,
            stateCode: response.data.uf,
          });
          setShowAddressFields(true);
        } else {
          message.error("CEP não encontrado.");
        }
      } catch (error) {
        console.error(error);
        message.error("Erro ao buscar o endereço.");
      }
    }
  };

  const handleCreate = async () => {
    try {
      const values = await form.validateFields();
      const { name, ibgeCode, stateName, stateCode } = values;

      console.log(name, ibgeCode, stateName, stateCode);

      if (isEditing) {
        await updateMunicipality(
          name,
          ibgeCode,
          stateName,
          stateCode,
          currentItem?.id
        );
      } else {
        await createMunicipality(name, ibgeCode, stateName, stateCode);
      }

      form.resetFields();
      setIsModalVisible(false);
      setIsEditing(false);
      setCurrentItem(null);
      setShowAddressFields(false);
      dataMunicipalities();
    } catch (error) {
      console.error("Erro ao criar/editar Município:", error);

      const errorMessage =
        error instanceof Error
          ? error.message
          : "Ocorreu um erro ao tentar criar/editar o município. Tente novamente mais tarde.";

      messageApi.open({
        type: "error",
        content: errorMessage,
      });
    }
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
    setIsEditing(false);
    setCurrentItem(null);
    setShowAddressFields(false);
  };

  // const handleEdit = (item: IPropsResponseMunicipalities) => {
  //   if (item) {
  //     setCurrentItem(item);
  //     setIsEditing(true);
  //     form.setFieldsValue(item);
  //     setIsModalVisible(true);
  //   }
  // };

  const handleDelete = async (id: string) => {
    try {
      await deleteMunicipality(id);
      message.success("Município deletado com sucesso!");
      dataMunicipalities();
    } catch (error) {
      const errorMessage =
        error instanceof Error
          ? error.message
          : "Erro ao deletar município. Tente novamente.";

      message.error(errorMessage);
    }
  };

  const columns: TableProps<IPropsResponseMunicipalities>["columns"] = [
    {
      title: "Município",
      dataIndex: "name",
      key: "name",
      render: (text: string) => <Typography.Text>{text}</Typography.Text>,
    },
    {
      title: "Estado",
      dataIndex: "stateName",
      key: "stateName",
      render: (text: string) => <Typography.Text>{text}</Typography.Text>,
    },
    {
      title: "Código IBGE",
      dataIndex: "ibgeCode",
      key: "ibgeCode",
      render: (text: string) => <Typography.Text>{text}</Typography.Text>,
    },
    {
      title: "Ações",
      key: "action",
      align: "right",
      render: (_, record: IPropsResponseMunicipalities) => (
        <Space size="middle">
          {/* <EditOutlined
            style={{ color: colors.info, cursor: "pointer", fontSize: 18 }}
            onClick={() => handleEdit(record)}
          /> */}
          <Popconfirm
            title="Tem certeza que deseja deletar este item?"
            onConfirm={() => handleDelete(record.ibgeCode)}
            okText="Sim"
            cancelText="Não"
            placement="topRight"
          >
            <DeleteOutlined
              style={{ color: colors.error, cursor: "pointer", fontSize: 18 }}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  console.log(
    form.getFieldValue(["name", "stateName", "ibgeCode", "stateCode"])
  );

  return (
    <Flex vertical>
      {contextHolder}
      <Typography.Title level={5} style={{ padding: 0 }}>
        Cadastro de Municípios
      </Typography.Title>
      <Row
        align="middle"
        justify="space-between"
        style={{ width: "100%", flexWrap: "nowrap", marginBottom: 20 }}
      >
        <Col style={{ width: "50%" }}>
          <CustomInput
            style={{ width: "50%" }}
            title="Pesquisar"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (e.key === "Enter") {
                handleSearch(searchText);
              }
            }}
            addonAfter={
              <SearchOutlined
                style={{ cursor: "pointer", color: colors.info }}
                onClick={() => handleSearch(searchText)}
              />
            }
          />
        </Col>
        <CustomButton
          type="primary"
          onClick={() => {
            form.resetFields();
            setIsEditing(false);
            setCurrentItem(null);
            setIsModalVisible(true);
          }}
        >
          <PlusOutlined /> Criar novo
        </CustomButton>
      </Row>

      {/* Table */}
      <Table<IPropsResponseMunicipalities>
        columns={columns}
        dataSource={filteredMunicipalities}
        loading={loading}
      />

      {/* Modal */}
      <Modal
        title={isEditing ? "Editar Item" : "Criar novo"}
        visible={isModalVisible}
        onOk={handleCreate}
        onCancel={handleCancel}
        okText={isEditing ? "Salvar" : "Criar"}
        cancelText="Cancelar"
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="postalCode"
            style={{ margin: 0 }}
            rules={[
              {
                required: true,
                message: "Por favor, informe o CEP!",
              },
            ]}
          >
            <CustomInput
              placeholder="Digite o CEP"
              title="CEP"
              maxLength={9}
              value={form.getFieldValue("postalCode")}
              onChange={(e) => {
                const formattedCep = formatPostalCode(e.target.value);
                form.setFieldsValue({ postalCode: formattedCep });
              }}
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key === "Enter") {
                  handleGetAddressByCep(form.getFieldValue("postalCode"));
                }
              }}
              addonAfter={
                <SearchOutlined
                  style={{ cursor: "pointer", color: colors.info }}
                  onClick={() =>
                    handleGetAddressByCep(form.getFieldValue("postalCode"))
                  }
                />
              }
            />
          </Form.Item>
          {showAddressFields && (
            <>
              <Form.Item
                name="name"
                style={{ margin: 0 }}
                rules={[
                  {
                    required: true,
                    message: "Por favor, informe o nome da cidade!",
                  },
                ]}
              >
                <Typography.Text strong>Cidade</Typography.Text>
                <ReadOnlyInput>{form.getFieldValue("name")}</ReadOnlyInput>
              </Form.Item>
              <Form.Item
                name="stateName"
                style={{ margin: 0 }}
                rules={[
                  {
                    required: true,
                    message: "Por favor, informe o nome do Estado!",
                  },
                ]}
              >
                <Typography.Text strong>Estado</Typography.Text>
                <ReadOnlyInput>
                  {form.getFieldValue("stateName")} -{" "}
                  {form.getFieldValue("stateCode")}
                </ReadOnlyInput>
              </Form.Item>
              <Form.Item
                name="ibgeCode"
                style={{ margin: 0 }}
                rules={[
                  {
                    required: true,
                    message: "Por favor, informe o Código do IBGE!",
                  },
                ]}
              >
                <Typography.Text strong>Código IBGE</Typography.Text>
                <ReadOnlyInput>{form.getFieldValue("ibgeCode")}</ReadOnlyInput>
              </Form.Item>
              <Form.Item name="stateCode" style={{ margin: 0 }} hidden>
                <CustomInput />
              </Form.Item>
            </>
          )}
        </Form>
      </Modal>
    </Flex>
  );
};

export default Municipalities;
