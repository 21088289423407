import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { AnimatePresence, motion } from "framer-motion";

import {
  Badge,
  Image,
  Layout,
  Menu,
  MenuProps,
  Row,
  FloatButton,
  Col,
  Flex,
} from "antd";
import {
  AppstoreOutlined,
  BellOutlined,
  LogoutOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";

import { colors } from "../../theme/colors";
import { items, MenuItem } from "./menu";

import ImageLogo from "../../assets/images/logo.svg";
import { useAuth } from "../../hooks/AuthContext";

const { Header, Content } = Layout;

interface AppHeaderProps {
  children: React.ReactNode;
}

const AppHeader: React.FC<AppHeaderProps> = ({ children }) => {
  const [current, setCurrent] = useState<string>("mail");
  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);
  const navigate = useNavigate();

  const isMobile = useMediaQuery({ maxWidth: 800 });

  const findRoute = (
    key: string,
    menuItems: MenuItem[]
  ): string | null => {
    for (const item of menuItems) {
      if (item.key === key && item.route) {
        return item.route;
      }
      if (item.children) {
        const route = findRoute(key, item.children);
        if (route) return route;
      }
    }
    return null;
  };

  // Handler para o clique do menu
  const handleMenuClick: MenuProps["onClick"] = ({ key }) => {
    setCurrent(key)
    const route = findRoute(key, items);
    if (route) {
      navigate(route); // Navega para a rota correspondente
    } else {
      console.warn(`Nenhuma rota encontrada para a chave: ${key}`);
    }
  };

  const { signOut } = useAuth();

  const handleSignOut = () => {
    signOut();
  };

  return (
    <Layout>
      <FloatButton
        icon={<WhatsAppOutlined style={{ color: colors.success }} />}
        onClick={() => console.log("onClick")}
      />
      <Header
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          background: colors.backgroundWhite,
          gap: 15,
          padding: "0px 24px",
          position: "relative",
        }}
      >
        <Image src={ImageLogo} preview={false} style={{ height: "35px" }} />
        {!isMobile && (
          <Menu
            mode="horizontal"
            selectedKeys={[current]}
            items={items}
            style={{ flex: 1, minWidth: 0, borderBottom: "none" }}
            onClick={handleMenuClick}
          />
        )}
        <Row align="middle" style={{ gap: 15 }}>
          <Row style={{ cursor: "pointer" }}>
            <Badge count={5} offset={[0, 5]} size="small">
              <BellOutlined style={{ fontSize: 20 }} />
            </Badge>
          </Row>
          <LogoutOutlined
            style={{ fontSize: 20, cursor: "pointer" }}
            onClick={handleSignOut}
          />
          {isMobile && (
            <AppstoreOutlined
              style={{ fontSize: 30 }}
              onClick={() => setIsOpenMenu(!isOpenMenu)}
            />
          )}
        </Row>
        <AnimatePresence>
          {isMobile && isOpenMenu && (
            <motion.div
              key="mobile-menu"
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: "auto", opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              transition={{ duration: 0.7, ease: "easeInOut" }}
              style={{
                backgroundColor: colors.backgroundWhite,
                position: "absolute",
                top: 64,
                right: 15,
                left: 15,
                boxShadow: "0 10px 20px -12px rgba(0,0,0,0.2)",
                borderBottomLeftRadius: 20,
                borderBottomRightRadius: 20,
                overflow: "hidden",
                width: 360,
              }}
            >
              <Flex wrap gap="10px" style={{ padding: 15 }}>
                {items.map((item) => (
                  <Col
                    style={{
                      background: colors.primary,
                      width: 160,
                      borderRadius: 10,
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                      color: colors.fontWhite,
                    }}
                  >
                    {item.label}
                  </Col>
                ))}
              </Flex>
            </motion.div>
          )}
        </AnimatePresence>
      </Header>
      <Content style={{ padding: "24px" }}>{children}</Content>
    </Layout>
  );
};

export default AppHeader;
