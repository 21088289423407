import React from 'react';

import { ConfigProvider } from 'antd';
import ptBR from 'antd/lib/locale/pt_BR';

import { colors } from '../colors';

const ConfigProviderAntd: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <>
      <ConfigProvider
        locale={ptBR}
        theme={{
          token: {
            colorPrimary: colors.primary,
          },
          components: {
            Button: {
              dangerColor: '#F00',
              primaryShadow: 'none',
            },
          }
        }}
      >
        {children}
      </ConfigProvider>
    </>
  )
}

export default ConfigProviderAntd;