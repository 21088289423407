export const handleApiError = (error: any) => {
  
  if (error.response?.status === 400) {
    return new Error('Erro de validação: os dados fornecidos estão incorretos.');
  }

  if (error.response?.status === 401) {
    return new Error('Não autorizado: você não tem permissão para realizar essa ação.');
  }

  if (error.response?.status === 404) {
    return new Error('Recurso não encontrado: o endpoint solicitado não existe.');
  }

  if (error.response?.status === 500) {
    return new Error('Erro interno do servidor: algo deu errado, tente novamente mais tarde.');
  }

  if (error.response?.status === 503) {
    return new Error('Serviço indisponível: o servidor está temporariamente fora do ar.');
  }

  // Erro genérico para qualquer outro tipo de falha
  return new Error('Erro desconhecido. Tente novamente mais tarde.');
};