import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  Col,
  Flex,
  Row,
  Space,
  Table,
  Typography,
  Modal,
  Form,
  Input,
  DatePicker,
  Popconfirm,
  message,
} from "antd";
import type { TableProps } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";

import CustomButton from "../../../components/Buttom";
import CustomSelect from "../../../components/Select";
import { colors } from "../../../theme/colors";
import { quotesData } from "./quoteMock";
import { QuoteTypes } from "./quoteTypes";

const Quotes: React.FC = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [filteredData, setFilteredData] = useState<QuoteTypes[]>(quotesData);

  const [filters, setFilters] = useState<{
    distributor: string[] | null;
    operationBase: string[] | null;
    issueDate: moment.Moment | null;
    operationDate: moment.Moment | null;
  }>({
    distributor: null,
    operationBase: null,
    issueDate: null,
    operationDate: null,
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [isEditing, setIsEditing] = useState(false);
  const [currentItem, setCurrentItem] = useState<QuoteTypes | null>(null);
  const [distributorOptions, setDistributorOptions] = useState<string[]>([]);
  const [operationBaseOptions, setOperationBaseOptions] = useState<string[]>(
    []
  );

  useEffect(() => {
    const uniqueDistributors = [
      ...new Set(quotesData.map((item) => item.distributor)),
    ];
    setDistributorOptions(uniqueDistributors);

    const uniqueOperationBases = [
      ...new Set(quotesData.map((item) => item.operationBase)),
    ];
    setOperationBaseOptions(uniqueOperationBases);
  }, []);

  const handleFiltersChange = (field: string, value: any) => {
    setFilters((prevFilters) => ({ ...prevFilters, [field]: value }));
  };

  const applyFilters = () => {
    const filtered = quotesData.filter((item) => {
      return (
        (!filters.distributor ||
          filters.distributor.length === 0 ||
          filters.distributor.some((distributor) =>
            item.distributor.toLowerCase().includes(distributor.toLowerCase())
          )) &&
        (!filters.operationBase ||
          filters.operationBase.length === 0 ||
          filters.operationBase.some((base) =>
            item.operationBase.toLowerCase().includes(base.toLowerCase())
          )) &&
        (!filters.issueDate ||
          filters.issueDate === null ||
          filters.issueDate.isSame(item.issueDate, "day")) &&
        (!filters.operationDate ||
          filters.operationDate === null ||
          filters.operationDate.isSame(item.operationDate, "day"))
      );
    });
    setFilteredData(filtered);
  };

  const handleCreate = () => {
    form
      .validateFields()
      .then((values) => {
        if (isEditing) {
          const updatedData = filteredData.map((quote) =>
            quote.id === currentItem?.id ? { ...quote, ...values } : quote
          );
          setFilteredData(updatedData);
          message.success("Quote updated successfully.");
        } else {
          const newQuote = { ...values, id: `newQuote${Date.now()}` };
          const newData = [...filteredData, newQuote];
          setFilteredData(newData);
          message.success("New quote created successfully.");
        }
        form.resetFields();
        setIsModalVisible(false);
        setIsEditing(false);
        setCurrentItem(null);
      })
      .catch((error) => {
        const errorMessage =
          error instanceof Error
            ? error.message
            : "Ocorreu um erro ao tentar criar/editar a categoria. Tente novamente mais tarde.";

        messageApi.open({
          type: "error",
          content: errorMessage,
        });
      });
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
    setIsEditing(false);
    setCurrentItem(null);
  };

  const handleEdit = (record: QuoteTypes) => {
    setCurrentItem(record);
    setIsEditing(true);
    form.setFieldsValue(record);
    setIsModalVisible(true);
  };

  const handleDelete = (id: string) => {
    const updatedData = filteredData.filter((quote) => quote.id !== id);
    setFilteredData(updatedData);
    messageApi.success("Quote deleted successfully.");
  };

  const columns: TableProps<QuoteTypes>["columns"] = [
    {
      title: "Distribuidor", // Distributor
      dataIndex: "distributor",
      key: "distributor",
      render: (text: string) => <Typography.Text>{text}</Typography.Text>,
    },
    {
      title: "Base de Operação", // Operation Base
      dataIndex: "operationBase",
      key: "operationBase",
      render: (text: string) => <Typography.Text>{text}</Typography.Text>,
    },
    {
      title: "Data de Emissão", // Issue Date
      dataIndex: "issueDate",
      key: "issueDate",
      render: (date: Date) => (
        <Typography.Text>{moment(date).format("DD/MM/YYYY")}</Typography.Text>
      ),
    },
    {
      title: "Data de Operação", // Operation Date
      dataIndex: "operationDate",
      key: "operationDate",
      render: (date: Date) => (
        <Typography.Text>{moment(date).format("DD/MM/YYYY")}</Typography.Text>
      ),
    },
    {
      title: "Valor da Operação", // Operation Value
      dataIndex: "operationValue",
      key: "operationValue",
      render: (text: string) => <Typography.Text>{text}</Typography.Text>,
    },
    {
      title: "Quantidade", // Quantity
      dataIndex: "quantity",
      key: "quantity",
      render: (quantity: number) => (
        <Typography.Text>{quantity}</Typography.Text>
      ),
    },
    {
      title: "Ações",
      key: "action",
      render: (_, record: QuoteTypes) => (
        <Space size="middle">
          <EditOutlined
            style={{ color: colors.info, cursor: "pointer", fontSize: 18 }}
            onClick={() => handleEdit(record)}
          />
          <Popconfirm
            title="Tem certeza que deseja deletar este item?"
            onConfirm={() => handleDelete(record.id)}
            okText="Sim"
            cancelText="Não"
            placement="topRight"
          >
            <DeleteOutlined
              style={{ color: colors.error, cursor: "pointer", fontSize: 18 }}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Flex vertical>
      {contextHolder}
      <Typography.Title level={2}>Cotações</Typography.Title>
      <Row
        align="middle"
        justify="space-between"
        style={{ width: "100%", flexWrap: "wrap", marginBottom: 20 }}
      >
        <Col
          span={24}
          style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}
        >
          <CustomSelect
            style={{ flex: 1, minWidth: "425px" }}
            placeholder="Selecione a Distribuidora"
            value={filters.distributor}
            onChange={(value) => handleFiltersChange("distributor", value)}
            options={distributorOptions.map((distributor) => ({
              value: distributor,
              label: distributor,
            }))}
            mode="multiple"
            allowClear
          />
          <CustomSelect
            style={{ flex: 1, minWidth: "425px" }}
            placeholder="Selecione a Base de Operação"
            value={filters.operationBase}
            onChange={(value) => handleFiltersChange("operationBase", value)}
            options={operationBaseOptions.map((base) => ({
              value: base,
              label: base,
            }))}
            mode="multiple"
            allowClear
          />
          <DatePicker
            style={{ flex: 1, maxWidth: "200px" }}
            placeholder="Data de Emissão"
            value={filters.issueDate}
            onChange={(date) => handleFiltersChange("issueDate", date)}
            format="DD/MM/YYYY"
          />
          <DatePicker
            style={{ flex: 1, maxWidth: "200px" }}
            placeholder="Data de Operação"
            value={filters.operationDate}
            onChange={(date) => handleFiltersChange("operationDate", date)}
            format="DD/MM/YYYY"
          />
          <CustomButton
            style={{ width: "10%" }}
            type="primary"
            onClick={applyFilters}
          >
            <SearchOutlined /> Filtrar
          </CustomButton>
        </Col>
      </Row>
      <Row>
        <CustomButton
          type="primary"
          onClick={() => {
            form.resetFields();
            setIsEditing(false);
            setCurrentItem(null);
            setIsModalVisible(true);
          }}
          style={{ marginBottom: "10px" }}
        >
          <PlusOutlined /> Nova cotação
        </CustomButton>
      </Row>

      <Table
        dataSource={filteredData}
        columns={columns}
        pagination={{
          pageSizeOptions: ["10", "15", "20", "25", "30"],
          showSizeChanger: true,
          defaultPageSize: 10,
        }}
      />

      <Modal
        title={isEditing ? "Edit Quote" : "Create New Quote"}
        visible={isModalVisible}
        onOk={handleCreate}
        onCancel={handleCancel}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            label="Distribuidor"
            name="distributor"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Base de Operação"
            name="operationBase"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Row align="middle" justify="space-between">
            <Form.Item
              label="Data de Emissão"
              name="issueDate"
              rules={[{ required: true }]}
              style={{ width: "50%" }}
            >
              <DatePicker format="DD/MM/YYYY" />
            </Form.Item>
            <Form.Item
              label="Data de Operação"
              name="operationDate"
              rules={[{ required: true }]}
              style={{ width: "50%" }}
            >
              <DatePicker format="DD/MM/YYYY" />
            </Form.Item>
          </Row>
          <Form.Item
            label="Valor da Operação"
            name="operationValue"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Quantidade"
            name="quantity"
            rules={[{ required: true }]}
          >
            <Input type="number" />
          </Form.Item>
        </Form>
      </Modal>
    </Flex>
  );
};

export default Quotes;
