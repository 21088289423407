import styled from "styled-components";
import { colors } from "../../../../theme/colors";

export const ReadOnlyInput = styled.div`
  border: 1px solid #d3d3d3;
  border-radius: 8px;
  padding: 4px 12px;
  background-color: ${colors.backgroundWhite};
  color: ${colors.fontBlack};
  cursor: default;
  margin-bottom: 8px;
  height: 2rem;
`;
