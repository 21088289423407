const setToken = (token: string) => {
  localStorage.setItem('APP_TOKEN', token);
};

const getToken = () => {
  const token = localStorage.getItem('APP_TOKEN');

  return token;
};

const removeToken = () => {
  localStorage.removeItem('APP_TOKEN');
};

export { getToken, setToken, removeToken };
