import * as Yup from 'yup';
import { parseCurrency, removeCaractersFormatting } from '../../../utils/masks';

export const RegisterSchema = Yup.object().shape({
  document: Yup.string().required("CNPJ/CPF é obrigatório"),
  tradeName: Yup.string().required("Nome de Identificação é obrigatório"),
  legalName: Yup.string().required("Razão Social/Nome é obrigatório"),
  email: Yup.string()
    .email("Email inválido")
    .required("Email é obrigatório"),
  phone: Yup.string().required("Telefone é obrigatório"),
  username: Yup.string().required("Nome de usuário é obrigatório"),
  password: Yup.string()
    .min(6, "A senha deve ter no mínimo 6 caracteres")
    .required("Senha é obrigatória"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password")], "As senhas não coincidem")
    .required("Confirmação de senha é obrigatória"),
  postalCode: Yup.string().required("CEP é obrigatório"),
  uf: Yup.string()
    .length(2, "UF deve ter 2 caracteres")
    .required("UF é obrigatória"),
  city: Yup.string().required("Cidade é obrigatória"),
  street: Yup.string().required("Endereço é obrigatório"),
  ibgeCode: Yup.string().required("Código IBGE é obrigatório"),
  neighborhood: Yup.string().required("Bairro é obrigatório"),
  numberAddress: Yup.string().required("Número do endereço é obrigatório"),
  complement: Yup.string().nullable(),
  type: Yup.string(),
  category_id: Yup.string().required("Categoria é obrigatória"),
  brand: Yup.string().required("Marca é obrigatória"),
  anp_code: Yup.string().nullable(),
  operation_base: Yup.string(),
  sales_volume: Yup.string(),
  average_revenue: Yup.string(),
  convenience_revenue: Yup.string(),
  oil_change_revenue: Yup.string(),
  fuel_pump_revenue: Yup.string(),
  subscription_plan: Yup.string(),
});

export interface IApiPayload {
  username: string;
  password: string;
  tradeName: string;
  legalName: string;
  document: string;
  email: string;
  phone: string;
  address: {
    type: string;
    ibgeCode: string;
    street: string;
    number: string;
    complement: string | null;
    neighborhood: string;
    postalCode: string;
  };
  anp_code: string | null;
  sales_volume: number;
  average_revenue: number;
  convenience_revenue: number;
  oil_change_revenue: number;
  fuel_pump_revenue: number;
  subscription_plan: string | null;
  category_id: string;
}

export interface IPropsValues {
  document: string,
  tradeName: string,
  legalName: string,
  email: string,
  phone: string,
  username: string,
  password: string,
  confirmPassword: string,
  postalCode: string,
  uf: string,
  city: string,
  street: string,
  ibgeCode: string,
  neighborhood: string,
  numberAddress: string,
  complement: string,
  type: string,
  category_id: string | undefined,
  brand: string | undefined,
  anp_code: string,
  operation_base: string | undefined,
  sales_volume: string,
  average_revenue: string,
  convenience_revenue: string,
  oil_change_revenue: string,
  fuel_pump_revenue: string,
  subscription_plan: string,
}

export const initialValues: IPropsValues = {
  document: "",
  tradeName: "",
  legalName: "",
  email: "",
  phone: "",
  username: "",
  password: "",
  confirmPassword: "",
  type: "",
  postalCode: "",
  uf: "",
  city: "",
  street: "",
  ibgeCode: "",
  neighborhood: "",
  numberAddress: "",
  complement: "",
  category_id: undefined,
  brand: undefined,
  anp_code: "",
  operation_base: undefined,
  sales_volume: "",
  average_revenue: "",
  convenience_revenue: "",
  oil_change_revenue: "",
  fuel_pump_revenue: "",
  
  subscription_plan: "Plano 1",
};

export const transformToApiPayload = (values: IPropsValues) => {
  return {
    username: values.username,
    password: values.password,
    tradeName: values.tradeName,
    legalName: values.legalName,
    document: removeCaractersFormatting(values.document),
    email: values.email,
    phone: removeCaractersFormatting(values.phone),
    address: {
      type: values.type || "residential", // Valor padrão
      ibgeCode: values.ibgeCode,
      street: values.street,
      number: values.numberAddress,
      complement: values.complement,
      neighborhood: values.neighborhood,
      postalCode: values.postalCode,
    },
    anp_code: values.anp_code,
    sales_volume: parseCurrency(values.sales_volume),
    average_revenue: parseCurrency(values.average_revenue),
    convenience_revenue: parseCurrency(values.convenience_revenue),
    oil_change_revenue: parseCurrency(values.oil_change_revenue),
    fuel_pump_revenue: parseCurrency(values.fuel_pump_revenue),
    subscription_plan: values.subscription_plan,
    category_id: values.category_id || "uuid", // Valor padrão caso undefined
  };
};

