import axios from 'axios';
import { REACT_APP_API_VIA_CEP } from '../config/constants';

export interface IReturnApiCep {
  cep: string;
  uf: string;
  localidade: string;
  logradouro: string;
  ibge: string;
  bairro: string;
  complemento: string;
}

export const apiViaCep = axios.create({
  baseURL: `${REACT_APP_API_VIA_CEP}/`,
});