import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import {
  Badge,
  Image,
  Layout,
  Menu,
  MenuProps,
  Row,
  FloatButton,
} from "antd";
import {
  AppstoreOutlined,
  BellOutlined,
  LogoutOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";

import { colors } from "../../theme/colors";
import { items, MenuItem } from "./menu";

import ImageLogo from "../../assets/images/logo.svg";
import { useAuth } from "../../hooks/AuthContext";

const { Header, Content } = Layout;

interface AppHeaderProps {
  children: React.ReactNode;
}

const AppHeader: React.FC<AppHeaderProps> = ({ children }) => {
  const [current, setCurrent] = useState<string>("mail");
  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);
  const navigate = useNavigate();

  const isMobile = useMediaQuery({ maxWidth: 800 });

  const findRoute = (
    key: string,
    menuItems: MenuItem[]
  ): string | null => {
    for (const item of menuItems) {
      if (item.key === key && item.route) {
        return item.route;
      }
      if (item.children) {
        const route = findRoute(key, item.children);
        if (route) return route;
      }
    }
    return null;
  };

  // Handler para o clique do menu
  const handleMenuClick: MenuProps["onClick"] = ({ key }) => {
    setCurrent(key)
    const route = findRoute(key, items);
    if (route) {
      navigate(route); // Navega para a rota correspondente
    } else {
      console.warn(`Nenhuma rota encontrada para a chave: ${key}`);
    }
  };

  const { signOut } = useAuth();

  const handleSignOut = () => {
    signOut();
  };

  return (
    <Layout>
      <FloatButton
        icon={<WhatsAppOutlined style={{ color: colors.success }} />}
        onClick={() => console.log("onClick")}
      />
      <Header
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          background: colors.backgroundWhite,
          gap: 15,
          padding: "0px 24px",
          position: "relative",
        }}
      >
        <Image src={ImageLogo} preview={false} style={{ height: "35px" }} />
          <Menu
            mode="horizontal"
            selectedKeys={[current]}
            items={items}
            style={{ flex: 1, minWidth: 0, borderBottom: "none" }}
            onClick={handleMenuClick}
          />        
        <Row align="middle" style={{ gap: 15 }}>
          <Row style={{ cursor: "pointer" }}>
            <Badge count={5} offset={[0, 5]} size="small">
              <BellOutlined style={{ fontSize: 20 }} />
            </Badge>
          </Row>
          <LogoutOutlined
            style={{ fontSize: 20, cursor: "pointer" }}
            onClick={handleSignOut}
          />
          {isMobile && (
            <AppstoreOutlined
              style={{ fontSize: 30 }}
              onClick={() => setIsOpenMenu(!isOpenMenu)}
            />
          )}
        </Row>
      </Header>
      <Content style={{ padding: "24px" }}>{children}</Content>
    </Layout>
  );
};

export default AppHeader;
