export interface Colors {
  primary: string;
  secondary: string;

  background: string;
  backgroundWhite: string;
  backgroundSecondary: string;

  fontPrimary: string;
  fontSecondary: string;
  fontWhite: string;
  fontBlack: string;

  warning: string;
  error: string;
  success: string;
  info: string;

  border: string;
  icon: string;
  iconPrimary: string;
}

export const colors: Colors = {
  primary: '#003f91',
  secondary: '#ff7f16',

  background: '#F5F5F5',
  backgroundWhite: '#FFFFFF',
  backgroundSecondary: '#90E0EF',

  fontPrimary: '#003f91',
  fontSecondary: '#90E0EF',
  fontWhite: '#FFFFFF',
  fontBlack: '#000000',

  warning: '#FFA500',
  error: '#FF0000',
  success: '#008000',
  info: '#1E90FF',

  border: '#003f91',
  icon: '#ff7f16',
  iconPrimary: '#003f91',
};
