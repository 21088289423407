
import React from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";

// Rotas Publicas
import PublicRoute from "./publicsRoutes";
import Login from "../pages/publics/Login";
import Register from "../pages/publics/Register";

// Rotas Privadas
import PrivateRoute from "./privateRoutes";
import AppHeader from "../components/AppHeader";
import Dashboard from "../pages/privates/Dashboard";
import MyAccount from "../pages/privates/MyAccount";

import CategoryCustomers from "../pages/privates/Configurations/CategoryCustomers";
import BrandANP from "../pages/privates/Configurations/BrandsANP";
import ProductANP from "../pages/privates/Configurations/ProductANP";
import Regions from "../pages/privates/Configurations/Regions";
import Quotes from "../pages/privates/Quotes/quotes";
import People from "../pages/privates/PeopleRegistration";
import DistributorsFuel from "../pages/privates/DistributorsFuel";

const RoutesApp: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<PublicRoute element={<Login />} />} />
        <Route
          path="/register"
          element={<PublicRoute element={<Register />} />}
        />
        <Route
          path="/"
          element={
            <PrivateRoute
              element={
                <AppHeader>
                  <Dashboard />
                </AppHeader>
              }
            />
          }
        />

        <Route
          path="/MyAccount"
          element={
            <PrivateRoute
              element={
                <AppHeader>
                  <MyAccount />
                </AppHeader>
              }
            />
          }
        />

        <Route
          path="/categoryCustomers"
          element={
            <PrivateRoute
              element={
                <AppHeader>
                  <CategoryCustomers />
                </AppHeader>
              }
            />
          }
        />
        <Route
          path="/flagANP"
          element={
            <PrivateRoute
              element={
                <AppHeader>
                  <BrandANP />
                </AppHeader>
              }
            />
          }
        />
        <Route
          path="/productanp"
          element={
            <PrivateRoute
              element={
                <AppHeader>
                  <ProductANP />
                </AppHeader>
              }
            />
          }
        />
        <Route
          path="/regions"
          element={
            <PrivateRoute
              element={
                <AppHeader>
                  <Regions />
                </AppHeader>
              }
            />
          }
        />
        <Route
          path="/quotes"
          element={
            <PrivateRoute
              element={
                <AppHeader>
                  <Quotes />
                </AppHeader>
              }
            />
          }
        />
        <Route
          path="/People"
          element={
            <PrivateRoute
              element={
                <AppHeader>
                  <People />
                </AppHeader>
              }
            />
          }
        />
        <Route
          path="/DistributorsFuel"
          element={
            <PrivateRoute
              element={
                <AppHeader>
                  <DistributorsFuel />
                </AppHeader>
              }
            />
          }
        />
      </Routes>
    </Router>
  );
};

export default RoutesApp;
