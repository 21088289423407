import React from "react";
import { Button, ButtonProps } from "antd";

interface CustomButtonProps extends ButtonProps {
  type?: "primary" | "default" | "dashed" | "text" | "link";
  children: React.ReactNode;
  onClick?: () => any;
}

const CustomButton: React.FC<CustomButtonProps> = ({
  type,
  children,
  ...rest
}) => {
  return (
    <Button type={type} {...rest}>
      {children}
    </Button>
  );
};

export default CustomButton;
