import { useState, useEffect, useContext, createContext } from "react";
import { getToken, removeToken, setToken } from "../services/utils/users";
import api from "../services/axios";
// import { IApiPayload } from "../pages/publics/Register/utils";

interface AuthContextProps {
  authenticated: boolean;
  loading: boolean;
  signIn: (email: string, password: string) => Promise<void>;
  signOut: () => void;
}

const AuthContext = createContext<AuthContextProps>({
  authenticated: false,
  loading: true,
  signIn: async () => Promise.resolve(),
  signOut: () => {},
});

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(false);
    const token = getToken();
    if (token) {
      setAuthenticated(true);
    }
    setLoading(false);
  }, []);

  const signIn = async (email: string, password: string): Promise<void> => {
    setLoading(true);
    try {
      const { data } = await api.post("/auth/login", {
        email,
        password,
      });

      setToken(data.accessToken);
      setAuthenticated(true);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      throw new Error(
        "Falha ao fazer login. Por favor, verifique suas credenciais e tente novamente!"
      );
    }
  };

  const signOut = () => {
    removeToken();
    setAuthenticated(false);
  };

  // const signUp = async (userData: IApiPayload): Promise<void> => {
  //   setLoading(true);
  //   try {
  //     await api.post("/customers/register", userData);
  //   } catch (err) {
  //   setLoading(false);

  //   const errorMessage =
  //     err.response?.data?.message || 'Falha ao criar conta. Tente novamente mais tarde.';
  //   message.error(errorMessage);

  //   throw new Error(errorMessage);
  // }
  // };

  return (
    <AuthContext.Provider value={{ authenticated, loading, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
