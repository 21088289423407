import api from "../axios";
import { IPropsResponseCategory, IPropsResponseBrands, IPropsResponseProductANP } from "./types";
import { handleApiError } from "../errorHandler";

export const getCategory = async (): Promise<IPropsResponseCategory[]> => {
  try {
    const response = await api.get('/customer-categories')
    return response.data
  } catch (error) {
    throw handleApiError(error);
  }
};

export const createCategory = async (name: string, anpCode: string) => {
  try {
    await api.post('/customer-categories', { name, anpCode });
  } catch (error: any) {
    throw handleApiError(error);
  }
};

export const updateCategory = async (name: string, anpCode: string, id?: string) => {
  try {
    await api.patch(`/customer-categories/${id}`, { name, anpCode })
  } catch (error) {
    throw handleApiError(error);
  }
};

export const deleteCategory = async (id?: string) => {
  try {
    await api.delete(`/customer-categories/${id}`)
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getBrands = async (): Promise<IPropsResponseBrands[]> => {
  try {
    const response = await api.get('/brands')
    return response.data
  } catch (error) {
    throw handleApiError(error);
  }
};

export const createBrand = async (name: string, type: string) => {
  try {
    await api.post('/brands', { name, type })
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getProductANP = async (): Promise<IPropsResponseProductANP[]> => {
  try {
    const response = await api.get('products_anp')

    return response.data
  } catch (error) {
    throw handleApiError(error);
  }
};

export const createProductANP = async (name: string, anpCode: string) => {
  try {
    await api.post('/products_anp', { name, anpCode })
  } catch (error) {
    throw handleApiError(error);
  }
};

export const updateProductANP = async (name: string, anpCode: string, id?: string) => {
  try {
    await api.patch(`/products_anp/${id}`, { name, anpCode })
  } catch (error) {
    throw handleApiError(error);
  }
};

export const deleteProductANP = async (id?: string) => {
  try {
    await api.delete(`/products_anp/${id}`)
  } catch (error) {
    throw handleApiError(error);
  }
};
