/* eslint-disable react-hooks/exhaustive-deps, @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  Col,
  Flex,
  Row,
  Space,
  Table,
  Typography,
  Modal,
  Form,
  Popconfirm,
  Select,
  Button,
  Tag,
  message,
} from "antd";
import type { TableProps } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  SearchOutlined,
  LinkOutlined,
  LeftOutlined,
} from "@ant-design/icons";

import CustomInput from "../../../../components/Input";
import CustomButton from "../../../../components/Buttom";
import { colors } from "../../../../theme/colors";
import { DataType } from "./types";
import {
  getRegions,
  createRegion,
  updateRegion,
  deleteRegion,
  getMunicipalities,
} from "../../../../services/configurations";
import {
  IPropsResponseRegions,
  IPropsResponseMunicipalities,
} from "../../../../services/configurations/types";

const Regions: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [isEditing, setIsEditing] = useState(false);
  const [currentItem, setCurrentItem] = useState<IPropsResponseRegions | null>(
    null
  );
  const [regionsData, setRegionsData] = useState<IPropsResponseRegions[]>([]);
  const [filteredRegionsData, setFilteredRegionsData] = useState<
    IPropsResponseRegions[]
  >([]);
  const [municipalities, setMunicipalities] = useState<
    IPropsResponseMunicipalities[]
  >([]);
  const [selectedMunicipalities, setSelectedMunicipalities] = useState<
    IPropsResponseMunicipalities[]
  >([]);
  const [showMunicipalitySelect, setShowMunicipalitySelect] = useState(false);
  const [selectedMunicipality, setSelectedMunicipality] =
    useState<IPropsResponseMunicipalities | null>(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");

  useEffect(() => {
    fetchRegionsData();
    fetchMunicipalitiesData();
  }, []);

  useEffect(() => {
    setFilteredRegionsData(regionsData);
  }, [regionsData]);

  const fetchRegionsData = async () => {
    setLoading(true);
    try {
      const data = await getRegions();
      setRegionsData(data);
    } catch (error) {
      console.error("Error fetching regions data:", error);
      messageApi.error("Erro ao buscar dados de regiões.");
    } finally {
      setLoading(false);
    }
  };

  const fetchMunicipalitiesData = async () => {
    setLoading(true);
    try {
      const data = await getMunicipalities();
      setMunicipalities(data);
    } catch (error) {
      console.error("Error fetching municipalities data:", error);
      messageApi.error("Erro ao buscar dados de municípios.");
    } finally {
      setLoading(false);
    }
  };

  const handleCreate = async () => {
    try {
      const values = await form.validateFields();

      const municipalityIds = selectedMunicipalities.map((m) => m.ibgeCode);

      const regionData = {
        name: values.name,
        municipalityIds: municipalityIds,
      };

      if (isEditing && currentItem?.id) {
        await updateRegion(currentItem.id, regionData);
        messageApi.success("Região atualizada com sucesso!");
      } else {
        await createRegion(regionData);
        messageApi.success("Nova região criada com sucesso!");
      }

      await fetchRegionsData();
      handleCancel();
    } catch (error) {
      console.error("Error creating/updating region:", error);
      messageApi.error(
        isEditing ? "Erro ao atualizar região." : "Erro ao criar nova região."
      );
    }
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
    setIsEditing(false);
    setCurrentItem(null);
    setSelectedMunicipalities([]);
    setShowMunicipalitySelect(false);
    setSelectedMunicipality(null);
  };

  const handleEdit = (id: string) => {
    const itemToEdit = regionsData.find((item) => item.id === id);
    if (itemToEdit) {
      setCurrentItem(itemToEdit);
      setIsEditing(true);
      form.setFieldsValue({ name: itemToEdit.name });

      const mappedMunicipalities = itemToEdit.municipalities
        .map((regionMunicipality) => {
          const fullMunicipality = municipalities.find(
            (m) => m.ibgeCode === regionMunicipality.ibgeCode
          );
          return fullMunicipality;
        })
        .filter((m) => m !== undefined) as IPropsResponseMunicipalities[];

      setSelectedMunicipalities(mappedMunicipalities);
      setIsModalVisible(true);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      await deleteRegion(id);
      messageApi.success("Região deletada com sucesso!");
      await fetchRegionsData();
    } catch (error) {
      console.error("Error deleting region:", error);
      messageApi.error("Erro ao deletar região.");
    }
  };

  const handleLinkMunicipality = () => {
    setShowMunicipalitySelect(!showMunicipalitySelect);
  };

  const handleAddMunicipality = () => {
    if (selectedMunicipality) {
      const alreadyAdded = selectedMunicipalities.some(
        (item) => item.ibgeCode === selectedMunicipality.ibgeCode
      );

      if (!alreadyAdded) {
        setSelectedMunicipalities([
          ...selectedMunicipalities,
          selectedMunicipality,
        ]);
        setSelectedMunicipality(null);
      } else {
        messageApi.warning("Município já vinculado.");
      }
    }
  };

  const handleRemoveMunicipality = (ibgeCode: string) => {
    const newMunicipalities = selectedMunicipalities.filter(
      (item) => item.ibgeCode !== ibgeCode
    );
    setSelectedMunicipalities(newMunicipalities);
  };

  const formattedRegionsData: DataType[] = filteredRegionsData.map(
    (region) => ({
      id: region.id,
      name: region.name,
      municipalities: region.municipalities.map((municipality) => ({
        id: municipality.ibgeCode,
        name: municipality.name,
      })),
    })
  );

  const handleSearch = (value: string) => {
    setSearchText(value);
    if (value.trim() === "") {
      setFilteredRegionsData(regionsData);
      return;
    }
    const lowerCaseValue = value.toLowerCase();
    const filteredData = regionsData.filter((region) => {
      return (
        region.name.toLowerCase().includes(lowerCaseValue) ||
        region.municipalities.some((municipality) =>
          municipality.name.toLowerCase().includes(lowerCaseValue)
        )
      );
    });
    setFilteredRegionsData(filteredData);
  };

  const columns: TableProps<DataType>["columns"] = [
    {
      title: "Região",
      dataIndex: "name",
      key: "name",
      render: (text: string) => <Typography.Text>{text}</Typography.Text>,
    },
    {
      title: "Cidades",
      key: "municipalities",
      dataIndex: "municipalities",
      render: (selectedMunicipalities: { id: string; name: string }[]) => (
        <Space size={[0, 8]} wrap>
          {selectedMunicipalities.map((municipality) => (
            <Tag key={municipality.id} color="blue">
              {municipality.name}
            </Tag>
          ))}
        </Space>
      ),
    },
    {
      title: "Ações",
      key: "action",
      align: "right",
      render: (_, record: DataType) => (
        <Space size="middle">
          <EditOutlined
            style={{ color: colors.info, cursor: "pointer", fontSize: 18 }}
            onClick={() => handleEdit(record.id)}
          />
          <Popconfirm
            title="Tem certeza que deseja deletar este item?"
            onConfirm={() => handleDelete(record.id)}
            okText="Sim"
            cancelText="Não"
            placement="topRight"
          >
            <DeleteOutlined
              style={{ color: colors.error, cursor: "pointer", fontSize: 18 }}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Flex vertical>
      {contextHolder}
      {/* Header */}
      <Row
        align="middle"
        justify="space-between"
        style={{ width: "100%", flexWrap: "nowrap", marginBottom: 20 }}
      >
        <Col style={{ width: "50%" }}>
          <CustomInput
            style={{ width: "50%" }}
            title="Pesquisar"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (e.key === "Enter") {
                handleSearch(searchText);
              }
            }}
            addonAfter={
              <SearchOutlined
                style={{ cursor: "pointer", color: colors.info }}
                onClick={() => handleSearch(searchText)}
              />
            }
          />
        </Col>
        <CustomButton
          type="primary"
          onClick={() => {
            form.resetFields();
            setIsEditing(false);
            setCurrentItem(null);
            setSelectedMunicipalities([]);
            setIsModalVisible(true);
          }}
        >
          <PlusOutlined /> Criar novo
        </CustomButton>
      </Row>

      {/* Table */}
      <Table<DataType>
        loading={loading}
        columns={columns}
        dataSource={formattedRegionsData}
        rowKey="id"
      />

      {/* Modal */}
      <Modal
        title={isEditing ? "Editar Região" : "Criar nova Região"}
        visible={isModalVisible}
        onOk={handleCreate}
        onCancel={handleCancel}
        okText={isEditing ? "Salvar" : "Criar"}
        cancelText="Cancelar"
      >
        <Form form={form} layout="vertical">
          {/* Nome */}
          <Form.Item
            name="name"
            style={{ marginBottom: "12px " }}
            rules={[
              { required: true, message: "O nome da região é obrigatório" },
            ]}
          >
            <CustomInput placeholder="Digite o nome" title="Nome da Região *" />
          </Form.Item>
          <Typography.Text>Municipios vinculados</Typography.Text>
          <Table
            dataSource={selectedMunicipalities}
            columns={[
              { title: "Nome", dataIndex: "name", key: "name" },
              { title: "Estado", dataIndex: "stateName", key: "stateName" },
              {
                title: "Ação",
                key: "action",
                render: (text, record) => (
                  <Popconfirm
                    title="Tem certeza que deseja desvincular este item?"
                    onConfirm={() => handleRemoveMunicipality(record.ibgeCode)}
                    okText="Sim"
                    cancelText="Não"
                    placement="topRight"
                  >
                    <DeleteOutlined
                      style={{
                        color: colors.error,
                        cursor: "pointer",
                        fontSize: 18,
                      }}
                    />
                  </Popconfirm>
                ),
              },
            ]}
            pagination={false}
            rowKey="ibgeCode"
            style={{ minHeight: 250 }}
          />
          {!showMunicipalitySelect && (
            <Button onClick={handleLinkMunicipality}>
              Vincular Município
              <LinkOutlined />
            </Button>
          )}
          {showMunicipalitySelect && (
            <Space.Compact style={{ width: "100%" }}>
              <Button
                onClick={handleLinkMunicipality}
                icon={<LeftOutlined />}
              />
              <Select
                placeholder="Selecione um Município"
                style={{ width: "100%" }}
                value={selectedMunicipality?.ibgeCode}
                onChange={(value) => {
                  const municipalitySelected = municipalities.find(
                    (municipality) => municipality.ibgeCode === value
                  );
                  setSelectedMunicipality(municipalitySelected || null);
                }}
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={municipalities
                  .filter(
                    (municipality) =>
                      !selectedMunicipalities.some(
                        (selected) =>
                          selected.ibgeCode === municipality.ibgeCode
                      )
                  )
                  .map((municipality) => ({
                    label: `${municipality.name}/${municipality.stateCode}`,
                    value: municipality.ibgeCode,
                  }))}
              />
              <Button onClick={handleAddMunicipality}>Adicionar</Button>
            </Space.Compact>
          )}
        </Form>
      </Modal>
    </Flex>
  );
};

export default Regions;
