import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// Rotas Publicas
import PublicRoute from './publicsRoutes';
import Login from '../pages/publics/Login';
import Register from '../pages/publics/Register';

// Rotas Privadas
import PrivateRoute from './privateRoutes';
import AppHeader from '../components/AppHeader';
import Dashboard from '../pages/privates/Dashboard';
import Registrations from '../pages/privates/Registrations';

const RoutesApp: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path='/login' element={<PublicRoute element={<Login />} />} />
        <Route path='/register' element={<PublicRoute element={<Register />} />} />

        <Route path='/' element={<PrivateRoute element={<AppHeader><Dashboard /></AppHeader>} />} />
        <Route path='/registrations' element={<PrivateRoute element={<AppHeader><Registrations /></AppHeader>} />} />
      </Routes>
    </Router>
  )

}

export default RoutesApp;