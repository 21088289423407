import React from 'react';
import { useAuth } from '../../../hooks/AuthContext';

const Dashboard: React.FC = () => {
  const { signOut } = useAuth();

  const handleLogout = () => {
    signOut();
  }

  return (
    <button onClick={handleLogout}>
      LOGOUT
    </button>
  );
}

export default Dashboard;