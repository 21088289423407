import React from "react";
import { Input, Typography } from "antd";
import type { InputProps as AntdInputProps } from "antd";

interface CustomInputProps extends AntdInputProps {
  title?: string;
  icon?: React.ReactNode;
}

const CustomInput: React.FC<CustomInputProps> = ({ title, icon, ...props }) => {
  return (
    <>
      {title && (
        <Typography.Text
          style={{
            marginBottom: 0,
            display: "block",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          ellipsis={{ tooltip: title }}
        >
          {title}
        </Typography.Text>
      )}
      <Input
        {...props}
        size="middle"
        style={{ marginBottom: 5 }}
        prefix={icon}
      />
    </>
  );
};

export default CustomInput;
