/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";
import { Col, Flex, Image, message, Row, Typography, Select } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  MinusOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import * as Yup from "yup";

import { ColCustom } from "./styles";
import CustomInput from "../../../../../components/Input/index";
import CustomButton from "../../../../../components/Buttom/index";
import CustomSelect from "../../../../../components/Select/index";
import {
  formatCpfCnpj,
  formatPhone,
  formatPostalCode,
  formatTextOnlyUpperCase,
} from "../../../../../utils/masks";
import {
  initialValues,
  IDocument,
  IContact,
  IAddress,
  IPropsValues,
  RegisterSchema,
} from "../utils";
import { apiViaCep, IReturnApiCep } from "../../../../../services/viaCep";
import {
  createPerson,
  updatePerson,
} from "../../../../../services/registrations";
import { stringify } from "querystring";

interface PeopleRegistrationFormProps {
  person: IPropsValues | undefined;
  onSubmit: (values: IPropsValues) => void;
  onCancel: () => void;
  isEditing: boolean;
}

const personTypeOptions = [
  { value: "individual", label: "Pessoa Física" },
  { value: "legal_entity", label: "Pessoa Jurídica" },
];

const documentTypeOptions = [
  { value: "cpf", label: "CPF" },
  { value: "cnpj", label: "CNPJ" },
  { value: "rg", label: "RG" },
  { value: "ie", label: "IE" },
];

const contactTypeOptions = [
  { value: "email", label: "E-mail" },
  { value: "landline", label: "Telefone" },
  { value: "mobile", label: "Celular" },
];

const addressTypeOptions = [
  { value: "residential", label: "Residencial" },
  { value: "commercial", label: "Comercial" },
];

const PeopleRegistrationForm = ({
  person,
  onSubmit,
  onCancel,
  isEditing,
}: PeopleRegistrationFormProps) => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [values, setValues] = useState<
    IPropsValues & { id?: string | undefined }
  >(person || initialValues);
  const [contextHolder, setContextHolder] = useState(null);

  useEffect(() => {
    setValues(person ? person : initialValues);
  }, [person]);

  const handleChange = (
    field: keyof IPropsValues | "documents" | "contacts" | "addresses",
    indexOrValue?: number | any,
    subField?: string,
    value?: any
  ) => {
    setValues((prevValues) => {
      if (
        field === "documents" ||
        field === "contacts" ||
        field === "addresses"
      ) {
        if (indexOrValue === undefined) {
          const newItem =
            field === "documents"
              ? { type: "cpf", value: "" }
              : field === "contacts"
              ? { type: "email", value: "", isPrimary: false }
              : {
                  type: "residential",
                  postalCode: "",
                  ibgeCode: "",
                  street: "",
                  neighborhood: "",
                  city: "",
                  uf: "",
                  number: "",
                  complement: "",
                };

          return {
            ...prevValues,
            [field]: [...prevValues[field], newItem],
          };
        } else if (
          typeof indexOrValue === "number" &&
          subField &&
          value !== undefined
        ) {
          return {
            ...prevValues,
            [field]: prevValues[field].map((item: any, i: number) =>
              i === indexOrValue ? { ...item, [subField]: value } : item
            ),
          };
        }
        return prevValues;
      } else {
        return {
          ...prevValues,
          [field]: indexOrValue,
        };
      }
    });
  };

  const handleGetAddressByCep = async (postalCode: string, index: number) => {
    const cleanPostalCode = postalCode.replace(/\D/g, "");
    if (cleanPostalCode.length === 8) {
      try {
        const response = await apiViaCep.get<IReturnApiCep>(
          `${cleanPostalCode}/json`
        );
        if (response.data) {
          handleChange("addresses", index, "uf", response.data.uf);
          handleChange("addresses", index, "street", response.data.logradouro);
          handleChange(
            "addresses",
            index,
            "neighborhood",
            response.data.bairro
          );
          handleChange("addresses", index, "city", response.data.localidade);
          handleChange("addresses", index, "ibgeCode", response.data.ibge);
        }
      } catch (error) {
        console.error(error);
        message.error("Erro ao buscar o endereço.");
      }
    }
  };

  const handleRemoveItem = (
    field: "documents" | "contacts" | "addresses",
    index: number
  ) => {
    setValues((prevValues) => {
      const arrayToFilter = prevValues[field];
      if (field === "documents") {
        return {
          ...prevValues,
          documents: (arrayToFilter as IDocument[]).filter(
            (_, i) => i !== index
          ),
        };
      }
      if (field === "contacts") {
        return {
          ...prevValues,
          contacts: (arrayToFilter as IContact[]).filter((_, i) => i !== index),
        };
      }
      if (field === "addresses") {
        return {
          ...prevValues,
          addresses: (arrayToFilter as IAddress[]).filter(
            (_, i) => i !== index
          ),
        };
      }
      return prevValues;
    });
  };

  const handleCancel = () => {
    setValues(initialValues);
    onSubmit(initialValues);
    onCancel();
  };

  const handleSubmit = async () => {
    try {
      await RegisterSchema.validate(values, { abortEarly: false });

      const formattedValues = {
        ...values,
        contacts: values.contacts.map((contact) => ({
          ...contact,
          value:
            contact.type === "landline" || contact.type === "mobile"
              ? formatPhone(contact.value)
              : contact.value,
        })),
        addresses: values.addresses.map((address) => ({
          ...address,
          postalCode: formatPostalCode(address.postalCode),
        })),
      } as IPropsValues;

      try {
        if (isEditing && values.id) {
          if (typeof values.id === "string") {
            const response = await updatePerson(formattedValues, values.id);
            message.success("Pessoa atualizada com sucesso!");
          } else {
            message.error("Erro: ID da pessoa inválido para atualização.");
            console.error("ID da pessoa inválido:", values.id);
          }
        } else {
          await createPerson(formattedValues);
          message.success("Pessoa cadastrada com sucesso!");
        }
        onSubmit(formattedValues);
      } catch (error) {
        console.error(
          isEditing ? "Erro ao atualizar pessoa:" : "Erro ao cadastrar pessoa:",
          error
        );
        message.error(
          isEditing ? "Erro ao atualizar pessoa." : "Erro ao cadastrar pessoa."
        );
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          message.error(error.message);
        });
      } else {
        console.error("Erro ao submeter:", err);
        message.error("Erro ao submeter o formulário.");
      }
    }
  };

  return (
    <Flex
      style={{
        padding: 0,
        flexDirection: isMobile ? "column" : "row",
        boxShadow: "none",
      }}
    >
      {contextHolder}
      <ColCustom
        isMobile={isMobile}
        isRigth={false}
        style={{
          boxShadow: "none",
          padding: 0,
        }}
      >
        <Typography.Title level={3} style={{ margin: "0 0 8px", padding: 0 }}>
          Cadastro de Pessoa
        </Typography.Title>

        <Col style={{ width: !isMobile ? "20%" : "100%" }}>
          <CustomSelect
            title="Tipo de Pessoa"
            value={values.type}
            onChange={(value) => handleChange("type", value)}
            placeholder="Selecione"
          >
            {personTypeOptions.map((option) => (
              <Select.Option key={option.value} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </CustomSelect>
        </Col>

        <Typography.Title level={4} style={{ margin: "0px 0 8px" }}>
          Dados
        </Typography.Title>
        {values.documents.map((document, index) => (
          <Row
            key={index}
            style={{
              gap: "6px",
              display: "flex",
              alignItems: "flex-end",
              marginBottom: "0px",
            }}
          >
            <Col style={{ width: !isMobile ? "20%" : "40%" }}>
              <CustomSelect
                title="Documento"
                value={document.type}
                onChange={(value) =>
                  handleChange("documents", index, "type", value)
                }
                placeholder="Selecione"
              >
                {documentTypeOptions.map((option) => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </CustomSelect>
            </Col>
            <Col style={{ width: !isMobile ? "75%" : "50%" }}>
              <CustomInput
                title="Número do Documento"
                placeholder="Informe o número do documento"
                value={formatCpfCnpj(values.documents[index].value)}
                onChange={(e) =>
                  handleChange("documents", index, "value", e.target.value)
                }
              />
            </Col>
            <Col
              style={{
                cursor: "pointer",
                alignSelf: "flex-end",
                fontSize: "40px",
              }}
            >
              {index > 0 ? (
                <DeleteOutlined
                  onClick={() => handleRemoveItem("documents", index)}
                  style={{
                    fontSize: 18,
                    padding: 0,
                  }}
                />
              ) : null}
            </Col>
          </Row>
        ))}
        <CustomButton
          type="dashed"
          style={{
            marginBottom: "12px",
            padding: "8px",
            height: "24px",
            fontSize: "12px",
          }}
          onClick={() => handleChange("documents")}
        >
          <PlusOutlined
            style={{
              fontSize: 10,
              padding: 0,
              cursor: "pointer",
              alignSelf: "flex-end",
            }}
          />
          Documento
        </CustomButton>
        <Row style={{ gap: "6px" }}>
          <Col style={{ width: !isMobile ? "47.5%" : "100%" }}>
            <CustomInput
              title="Razão Social / Nome *"
              placeholder="Informe sua Razão Social ou o seu Nome"
              value={values.legalName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChange("legalName", e.target.value)
              }
            />
          </Col>
          <Col style={{ width: !isMobile ? "47.5%" : "100%" }}>
            <CustomInput
              title="Nome Fantasia / Nome Identificador"
              placeholder="Informe o nome fantasia ou nome identificador"
              value={values.tradeName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChange("tradeName", e.target.value)
              }
            />
          </Col>
        </Row>
        <Typography.Title level={4} style={{ margin: "10px 0 5px" }}>
          Contatos
        </Typography.Title>
        {values.contacts.map((contact, index) => (
          <Row
            key={index}
            style={{
              gap: "6px",
              display: "flex",
              alignItems: "flex-end",
              marginBottom: "0px",
            }}
          >
            <Col style={{ width: !isMobile ? "20%" : "40%" }}>
              <CustomSelect
                title="Tipo de Contato"
                value={contact.type}
                onChange={(value) =>
                  handleChange("contacts", index, "type", value)
                }
                placeholder="Selecione"
              >
                {contactTypeOptions.map((option) => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </CustomSelect>
            </Col>
            <Col style={{ width: !isMobile ? "75%" : "50%" }}>
              <CustomInput
                title={"Contato"}
                placeholder="Informe o o contato"
                value={values.contacts[index].value}
                onChange={(e) =>
                  handleChange("contacts", index, "value", e.target.value)
                }
                style={{
                  width: "100%",
                  marginBottom: "10px",
                  alignSelf: "flex-end",
                }}
              />
            </Col>
            <Col
              style={{
                cursor: "pointer",
                alignSelf: "flex-end",
                fontSize: "40px",
              }}
            >
              {index !== 0 ? (
                <DeleteOutlined
                  onClick={() => handleRemoveItem("contacts", index)}
                  style={{
                    fontSize: 18,
                    padding: 0,
                  }}
                />
              ) : null}
            </Col>
          </Row>
        ))}
        <CustomButton
          type="dashed"
          style={{
            marginBottom: "12px",
            padding: "8px",
            height: "24px",
            fontSize: "12px",
          }}
          onClick={() => handleChange("contacts")}
        >
          <PlusOutlined
            style={{
              fontSize: 10,
              padding: 0,
            }}
          />
          Contato
        </CustomButton>

        <Typography.Title level={4} style={{ margin: "10px 0 5px" }}>
          Endereços
        </Typography.Title>
        {values.addresses.map((address, index) => (
          <div key={index}>
            <Row
              style={{
                gap: "6px",
                flexFlow: "wrap",
                display: "flex",
                ...(index !== 0
                  ? {
                      borderTop: "2px solid rgba(0, 0, 0, 0.1)",
                      marginTop: "10px",
                      paddingTop: "10px",
                    }
                  : {}),
                width: "100%",
              }}
            >
              <Col style={{ width: !isMobile ? "25%" : "95%" }}>
                <CustomSelect
                  title="Tipo de Endereço"
                  value={address.type}
                  placeholder="Selecione"
                  onChange={(value) =>
                    handleChange("addresses", index, "type", value)
                  }
                  style={{ width: "100%" }}
                >
                  {addressTypeOptions.map((option) => (
                    <Select.Option key={option.value} value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}
                </CustomSelect>
              </Col>
              <Col style={{ width: !isMobile ? "25%" : "95%" }}>
                <CustomInput
                  title="CEP"
                  placeholder="CEP"
                  value={formatPostalCode(values.addresses[index].postalCode)}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChange(
                      "addresses",
                      index,
                      "postalCode",
                      e.target.value
                    )
                  }
                  onBlur={(e) => handleGetAddressByCep(e.target.value, index)}
                />
              </Col>
              <Col style={{ width: !isMobile ? "44%" : "95%" }}>
                <CustomInput
                  title="UF"
                  placeholder="UF"
                  value={formatTextOnlyUpperCase(values.addresses[index].uf)}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChange("addresses", index, "uf", e.target.value)
                  }
                  disabled={!!values.addresses[index].uf}
                />
              </Col>
            </Row>
            <Row style={{ width: !isMobile ? "95%" : "95%" }}>
              <CustomInput
                title="Endereço"
                placeholder="Endereço"
                value={values.addresses[index].street}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange("addresses", index, "street", e.target.value)
                }
              />
            </Row>
            <Row
              style={{
                gap: "6px",
                flexFlow: "nowrap",
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
              }}
            >
              <Col style={{ width: !isMobile ? "20%" : "95%" }}>
                <CustomInput
                  title="Número"
                  placeholder="Número"
                  value={values.addresses[index].number}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChange("addresses", index, "number", e.target.value)
                  }
                />
              </Col>

              <Col style={{ width: !isMobile ? "74.5%" : "95%" }}>
                <CustomInput
                  title="Complemento"
                  placeholder="Informe um complemento"
                  value={values.addresses[index].complement}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChange(
                      "addresses",
                      index,
                      "complement",
                      e.target.value
                    )
                  }
                />
              </Col>
            </Row>
            <Row
              style={{
                gap: "6px",

                display: "flex",
              }}
            >
              <Col style={{ width: !isMobile ? "47.25%" : "95%" }}>
                <CustomInput
                  title="Bairro"
                  placeholder="Informe o Bairro"
                  value={values.addresses[index].neighborhood}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChange(
                      "addresses",
                      index,
                      "neighborhood",
                      e.target.value
                    )
                  }
                  disabled={!!values.addresses[index].neighborhood}
                />
              </Col>
              <Col style={{ width: !isMobile ? "47.25%" : "95%" }}>
                <CustomInput
                  title="Cidade"
                  placeholder="Informe a Cidade"
                  value={values.addresses[index].city}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChange("addresses", index, "city", e.target.value)
                  }
                  disabled={!!values.addresses[index].city}
                />
              </Col>
              {index !== 0 ? (
                <Col
                  style={{
                    cursor: "pointer",
                    alignSelf: "flex-end",
                    fontSize: "40px",
                  }}
                >
                  <DeleteOutlined
                    onClick={() => handleRemoveItem("addresses", index)}
                    style={{
                      fontSize: 18,
                      padding: 0,
                    }}
                  />
                </Col>
              ) : null}
            </Row>
          </div>
        ))}
        <CustomButton
          type="dashed"
          style={{
            marginTop: "0px",
            marginBottom: "8px",
            padding: "8px",
            height: "24px",
            fontSize: "12px",
          }}
          onClick={() => handleChange("addresses")}
        >
          <PlusOutlined
            style={{
              fontSize: 10,
              padding: 0,
            }}
          />
          Endereço
        </CustomButton>

        <Row
          style={{
            marginTop: "8px",
            gap: "10px",
            flexFlow: "nowrap",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <CustomButton onClick={handleCancel}>Cancelar</CustomButton>

          <CustomButton type="primary" onClick={handleSubmit}>
            {isEditing ? "Atualizar Pessoa" : "Cadastrar Pessoa"}
          </CustomButton>
        </Row>
      </ColCustom>
    </Flex>
  );
};

export default PeopleRegistrationForm;
