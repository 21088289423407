import React from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { Flex, Row, Card, Table } from "antd";
import { columns, dataGasolina, tableData } from "./mock";

const Dashboard: React.FC = () => {
  return (
    <Flex vertical style={{ gap: 10 }}>
      <Row>
        <Card title="Tabela de Preços" style={{ width: "100%", overflowX: "auto" }}>
          <div style={{ overflowX: "auto" }}>
            <Table dataSource={tableData} columns={columns} pagination={false} />
          </div>
        </Card>
      </Row>
      <Row style={{ width: "100%", flexWrap: "nowrap", gap: 10 }}>
        <Card title="Preço da Gasolina por Distribuidora" style={{ width: "100%" }}>
          <ResponsiveContainer width="100%" height={400}>
            <LineChart data={dataGasolina} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" tick={{ fontSize: 12 }} />
              <YAxis domain={[5, 7]} label={{ value: "Preço (R$)", angle: -90, position: "insideLeft" }} tick={{ fontSize: 12 }} />
              <Tooltip formatter={(value, name) => [
                typeof value === "number" ? `R$ ${value.toFixed(2)}` : value,
                String(name).replace("_", " ")
              ]} />
              <Legend />
              <Line type="monotone" dataKey="distribuidora_A" stroke="#6a0dad" name="Distribuidora A" />
              <Line type="monotone" dataKey="distribuidora_B" stroke="#008000" name="Distribuidora B" />
            </LineChart>
          </ResponsiveContainer>
        </Card>
      </Row>
    </Flex>
  );
};

export default Dashboard;

// nome produto
// distribuidora
// preço de aquisição
// valor medio mes
// preço medio revenda


// filtros = produto, distribuidora e ordenação(menor e maior preço, )
