import React, { useState } from "react";
import {
  Col,
  Flex,
  Row,
  Space,
  Table,
  Typography,
  Modal,
  Form,
  Popconfirm,
} from "antd";
import type { TableProps } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";

import CustomInput from "../../../../components/Input";
import CustomButton from "../../../../components/Buttom";
import { colors } from "../../../../theme/colors";
import { DataType } from "./types";
import { data } from "./mock";

const Regions: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [isEditing, setIsEditing] = useState(false);
  const [currentItem, setCurrentItem] = useState<DataType | null>(null);

  const handleCreate = () => {
    form.validateFields().then((values) => {
      if (isEditing) {
        console.log("Edit item:", { ...currentItem, ...values });
      } else {
        console.log("New Região created:", values);
      }
      // Adicione lógica para salvar os dados
      form.resetFields();
      setIsModalVisible(false);
      setIsEditing(false);
      setCurrentItem(null);
    });
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
    setIsEditing(false);
    setCurrentItem(null);
  };

  const handleEdit = (id: string) => {
    const itemToEdit = data.find((item) => item.id === id);
    if (itemToEdit) {
      setCurrentItem(itemToEdit);
      setIsEditing(true);
      form.setFieldsValue(itemToEdit); // Preenche o formulário com os dados do item
      setIsModalVisible(true);
    }
  };

  const handleDelete = (id: string) => {
    console.log(`Deleting item with id: ${id}`);
    // Adicione sua lógica de exclusão aqui
  };

  const columns: TableProps<DataType>["columns"] = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: string) => <Typography.Text>{text}</Typography.Text>,
    },
    {
      title: "Região",
      dataIndex: "regions",
      key: "regions",
      render: (text: string) => <Typography.Text>{text}</Typography.Text>,
    },
    {
      title: "Ações",
      key: "action",
      align: "right",
      render: (_, record: DataType) => (
        <Space size="middle">
          <EditOutlined
            style={{ color: colors.info, cursor: "pointer", fontSize: 18 }}
            onClick={() => handleEdit(record.id)}
          />
          <Popconfirm
            title="Tem certeza que deseja deletar este item?"
            onConfirm={() => handleDelete(record.id)} // Chama a função de deletar
            okText="Sim"
            cancelText="Não"
            placement="topRight"
          >
            <DeleteOutlined
              style={{ color: colors.error, cursor: "pointer", fontSize: 18 }}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Flex vertical>
      {/* Header */}
      <Row
        align="middle"
        justify="space-between"
        style={{ width: "100%", flexWrap: "nowrap", marginBottom: 20 }}
      >
        <Col style={{ width: "50%" }}>

          <CustomInput
            style={{ width: "50%" }}
            title="Pesquisar"
            addonAfter={
              <SearchOutlined
                style={{ cursor: "pointer", color: colors.info }}
                onClick={() => console.log("Ícone de pesquisa clicado!")}
              />
            }
          />
        </Col>
        <CustomButton
          type="primary"
          onClick={() => {
            form.resetFields();
            setIsEditing(false);
            setCurrentItem(null);
            setIsModalVisible(true);
          }}
        >
          <PlusOutlined /> Criar novo
        </CustomButton>
      </Row>

      {/* Table */}
      <Table<DataType> columns={columns} dataSource={data} />

      {/* Modal */}
      <Modal
        title={isEditing ? "Editar Item" : "Criar novo"}
        visible={isModalVisible}
        onOk={handleCreate}
        onCancel={handleCancel}
        okText={isEditing ? "Salvar" : "Criar"}
        cancelText="Cancelar"
      >
        <Form form={form} layout="vertical">
          {/* Nome */}
          <Form.Item name="name" style={{ margin: 0 }}>
            <CustomInput placeholder="Digite o nome" title="Nome" />
          </Form.Item>

          {/* Região */}
          <Form.Item name="Região" style={{ margin: 0 }}>
            <CustomInput placeholder="Digite a Região" title="Região" />
          </Form.Item>
        </Form>
      </Modal>
    </Flex>
  );
};

export default Regions;
