import React, { useState } from 'react';
import { Flex, Image, Row, Typography, message } from 'antd';
import { EyeInvisibleOutlined, EyeOutlined, LoadingOutlined } from '@ant-design/icons';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import ImageLogin from '../../../assets/images/image-login.png';
import ImageLogo from '../../../assets/images/logo.svg';

import { useAuth } from '../../../hooks/AuthContext';
import { LoginSchema } from './LoginSchema';

import CustomInput from '../../../components/Input';
import { colors } from '../../../theme/colors';
import CustomButton from '../../../components/Buttom';

const Login: React.FC = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const { signIn, loading } = useAuth();
  const navigate = useNavigate()

  const isMobile = useMediaQuery({ maxWidth: 900 });

  const [lockPassword, setLockPassword] = useState<boolean>(true);

  const handleLogin = async (email: string, password: string) => {
    try {
      await signIn(email, password);
    } catch (error) {
      messageApi.open({
        type: 'error',
        content: 'Falha ao fazer login. Por favor, verifique suas credenciais e tente novamente!',
      });
    }
  }

  return (
    <Flex style={{ height: '100vh', width: '100%' }}>
      {contextHolder}
      <Row justify='center' align='middle' style={{ width: '100%', padding: isMobile ? 0 : '20px' }}>
        <Flex vertical style={{ backgroundColor: colors.backgroundWhite, width: '100%', maxWidth: 540, borderRadius: isMobile ? 0 : 40, padding: 20, height: isMobile ? '100%' : 'auto' }}>
          <Row
            align='middle'
            justify='center'
            style={{ marginBottom: '20px' }}
          >
            <Image
              style={{ width: '300px' }}
              src={ImageLogo}
              preview={false}
            />
          </Row>
          <Typography.Title level={2}>Login</Typography.Title>
          <Formik
            initialValues={{ email: '', password: '' }}
            validationSchema={LoginSchema}
            onSubmit={(values, { setSubmitting }) => {
              handleLogin(values.email, values.password);
              setSubmitting(false);
            }}
          >
            {({ setFieldValue, values }) => (
              <Form>
                <Field name='email'>
                  {({ field }: { field: any }) => (
                    <Row>
                      <CustomInput
                        {...field}
                        title='E-Mail'
                        placeholder='Endereço de E-Mail'
                        value={values.email}
                        onChange={(e) => setFieldValue('email', e.target.value)}
                      />
                    </Row>
                  )}
                </Field>
                <ErrorMessage name='email'>
                  {(msg) => <Typography.Text type='danger'>{msg}</Typography.Text>}
                </ErrorMessage>
                <Field name='password'>
                  {({ field }: { field: any }) => (
                    <Row style={{ marginTop: 20, position: 'relative' }}>
                      <CustomInput
                        {...field}
                        title='Senha'
                        placeholder='Senha'
                        value={values.password}
                        onChange={(e) => setFieldValue('password', e.target.value)}
                        type={lockPassword ? 'password' : 'text'}
                      />
                      {lockPassword ? 
                        <EyeOutlined style={{ fontSize: 20, color: colors.iconPrimary, position: 'absolute', right: 10, top: 28 }} onClick={() => setLockPassword(!lockPassword)}/>
                        : <EyeInvisibleOutlined style={{ fontSize: 20, color: colors.iconPrimary, position: 'absolute', right: 10, top: 28 }} onClick={() => setLockPassword(!lockPassword)}/>
                      }
                    </Row>
                  )}
                </Field>
                <ErrorMessage name='password'>
                  {(msg) => <Typography.Text type='danger'>{msg}</Typography.Text>}
                </ErrorMessage>
                <Row align='middle' justify='end' style={{ margin: '12px 0' }}>
                  <CustomButton
                    type='text'
                    style={{ color: colors.primary }}
                  >
                    Esqueci minha senha
                  </CustomButton>
                </Row>
                <Row>
                  <CustomButton
                    type='primary'
                    size='large'
                    style={{ marginBottom: 10, width: '100%' }}
                    htmlType="submit"
                  >
                    {loading ? <LoadingOutlined style={{ fontSize: 20 }} /> : 'Entrar'}
                  </CustomButton>
                </Row>
                <Row>
                  <CustomButton
                    style={{ width: '100%' }}
                    type='default'
                    size='large'
                    onClick={() => navigate('/register')}
                  >
                    Criar uma conta
                  </CustomButton>
                </Row>
              </Form>
            )}
          </Formik>
        </Flex>
      </Row>
      {!isMobile && (
        <Row justify='center' align='middle' style={{ width: '100%', padding: 20 }}>
          <Image
            style={{ borderRadius: '10px' }}
            src={ImageLogin}
            preview={false}
          />
        </Row>
      )}
    </Flex>
  );
}

export default Login;