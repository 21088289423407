import api from "../axios";
import { IPropsResponsePerson } from "./types";
import { handleApiError } from "../errorHandler";
import { IPropsValues } from "../../pages/privates/Registrations/PeopleRegistration/utils";

export const getPeople = async (): Promise<IPropsResponsePerson[]> => {
  try {
    const response = await api.get("/people");
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const createPerson = async (personData: IPropsValues) => {
  try {
    await api.post("/people", personData);
  } catch (error) {
    throw handleApiError(error);
  }
};

export const updatePerson = async (personData: IPropsValues, id: string) => {
  try {
    await api.patch(`/people/${id}`, personData);
  } catch (error) {
    throw handleApiError(error);
  }
};

export const deletePerson = async (id: string) => {
  try {
    await api.delete(`/people/${id}`);
  } catch (error) {
    throw handleApiError(error);
  }
};
