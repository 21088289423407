/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";
import { Form, Input, Button, Modal, Typography, Space, Row, Col } from "antd";
import { SaveOutlined, EditOutlined } from "@ant-design/icons";
import { colors } from "../../../theme/colors";

// Dados fictícios para demonstração
const initialAccountData = {
  username: "joaosilva",
  name: "João Silva",
  email: "joao.silva@exemplo.com",
  phone: "(11) 98765-4321",
  address: "Rua Exemplo, 123, SP",
  password: "********",
  plan: "Plano Premium",
  price: "R$ 99,90",
  validity: "30/06/2025",
};

const UserAccount = () => {
  const [formAccount, setFormAccount] = useState(initialAccountData);
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(formAccount);
  }, [form, formAccount]);

  const handleSave = async (values: typeof formAccount) => {
    setLoading(true);
    // Simular o salvamento de dados
    setTimeout(() => {
      setLoading(false);
      setFormAccount(values); // Atualiza os dados com os valores do formulário
      setIsEditing(false); // Desativa o modo de edição
    }, 1000);
  };

  const handleEdit = () => {
    setIsEditing(true); // Ativa o modo de edição
  };

  return (
    <div style={{ padding: 24, backgroundColor: "#f9f9f9", borderRadius: 8 }}>
      <Typography.Title level={3}>Minha Conta</Typography.Title>

      <Row gutter={[16, 24]}>
        <Col span={12}>
          <Typography.Text strong>Nome de Usuário:</Typography.Text>
          <Typography.Text style={{ display: "block" }}>{formAccount.username}</Typography.Text>
        </Col>
        <Col span={12}>
          <Typography.Text strong>Nome:</Typography.Text>
          <Typography.Text style={{ display: "block" }}>{formAccount.name}</Typography.Text>
        </Col>
      </Row>

      <Row gutter={[16, 24]}>
        <Col span={12}>
          <Typography.Text strong>E-mail:</Typography.Text>
          <Typography.Text style={{ display: "block" }}>{formAccount.email}</Typography.Text>
        </Col>
        <Col span={12}>
          <Typography.Text strong>Telefone:</Typography.Text>
          <Typography.Text style={{ display: "block" }}>{formAccount.phone}</Typography.Text>
        </Col>
      </Row>

      <Row gutter={[16, 24]}>
        <Col span={12}>
          <Typography.Text strong>Endereço:</Typography.Text>
          <Typography.Text style={{ display: "block" }}>{formAccount.address}</Typography.Text>
        </Col>
        <Col span={12}>
          <Typography.Text strong>Senha:</Typography.Text>
          <Typography.Text style={{ display: "block" }}>{formAccount.password}</Typography.Text>
        </Col>
      </Row>

      <Row gutter={[16, 24]}>
        <Col span={12}>
          <Typography.Text strong>Plano de Assinatura:</Typography.Text>
          <Typography.Text style={{ display: "block" }}>{formAccount.plan}</Typography.Text>
        </Col>
        <Col span={6}>
          <Typography.Text strong>Preço:</Typography.Text>
          <Typography.Text style={{ display: "block" }}>{formAccount.price}</Typography.Text>
        </Col>
        <Col span={6}>
          <Typography.Text strong>Validade:</Typography.Text>
          <Typography.Text style={{ display: "block", color: colors.primary }}>
            {formAccount.validity}
          </Typography.Text>
        </Col>
      </Row>

      <Space style={{ marginTop: 24 }}>
        <Button
          type="primary"
          icon={<EditOutlined />}
          onClick={handleEdit}
          style={{ backgroundColor: colors.info, borderColor: colors.info }}
        >
          Editar
        </Button>
      </Space>

      {/* Modal para Edição */}
      <Modal
        title="Editar Informações"
        visible={isEditing}
        onCancel={() => setIsEditing(false)}
        footer={null}
        centered
      >
        <Form
          form={form}
          initialValues={formAccount}
          onFinish={handleSave}
          layout="vertical"
        >
          <Form.Item
            label="Nome de Usuário"
            name="username"
            rules={[{ required: true, message: "Por favor, insira seu nome de usuário" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Nome"
            name="name"
            rules={[{ required: true, message: "Por favor, insira seu nome" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="E-mail"
            name="email"
            rules={[{ required: true, message: "Por favor, insira seu e-mail" }]}
          >
            <Input type="email" />
          </Form.Item>

          <Form.Item
            label="Telefone"
            name="phone"
            rules={[{ required: true, message: "Por favor, insira seu telefone" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Endereço"
            name="address"
            rules={[{ required: true, message: "Por favor, insira seu endereço" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Senha"
            name="password"
            rules={[{ required: true, message: "Por favor, insira sua senha" }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label="Plano de Assinatura"
            name="plan"
            rules={[{ required: true, message: "Por favor, insira seu plano" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Preço"
            name="price"
            rules={[{ required: true, message: "Por favor, insira o preço do plano" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Validade"
            name="validity"
            rules={[{ required: true, message: "Por favor, insira a data de validade" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item>
            <Button
              type="default"
              onClick={() => setIsEditing(false)}
              style={{ marginRight: 8 }}
            >
              Cancelar
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              icon={<SaveOutlined />}
            >
              Salvar
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default UserAccount;
