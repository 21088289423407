export type MenuItem = {
  label: string;
  key: string;
  route?: string;
  children?: MenuItem[];
  onClick?: () => void;
};

export const items: MenuItem[] = [
  { label: "Dashboard", key: "dashboard", route: "/" },

  {
    label: "Cotações",
    key: "quotes",
    children: [
      { label: "Cliente", key: "QuotesClients", route: "/quotas/customers" },
      { label: "Distribuidora", key: "QuotesDistributor" }, //, route: "/QuotesDistributors" },
    ],
  },

  {
    label: "Cadastros",
    key: "registrations",
    children: [
      { label: "Clientes", key: "Clients" }, //, route: "/Clients" },
      { label: "Pessoas", key: "People", route: "/People" },

      {
        label: "Distribuidoras",
        key: "DistributorsFuel",
        route: "/DistributorsFuel",
      },
      { label: "Base de Operações", key: "OperationBase" }, //, route: "/OperationBase", },

      { label: "Consultores", key: "Consultants" }, //, route: "/Consultants" },
    ],
  },

  {
    label: "Configurações",
    key: "Configurations",
    children: [
      {
        label: "Categorias de Clientes",
        key: "CategoryCustomers",
        route: "/categoryCustomers",
      },

      { label: "Produtos ANP", key: "productanp", route: "/productANP" },
      { label: "Bandeiras ANP", key: "flaganp", route: "/flagANP" },

      { label: "Planos e Preços", key: "PlanPriceSubscription" }, //, route: "/planPriceSubscription", },

      { label: "Municipios", key: "Municipality", route: "/municipalities" },
      { label: "Regiões ANP", key: "regionsanp", route: "/regionsANP" },

      { label: "Usuários", key: "ConfigUsers" }, //, route: "/ConfigUsers" },
      { label: "Perfil de Usuários", key: "ConfigUsersProfiles" }, //, route: "/ConfigUsersProfiles" },
    ],
  },

  {
    label: "Blog",
    key: "blog",
    onClick: () =>
      window.open(
        "https://site.salespetro.com.br",
        "_blank",
        "noopener,noreferrer"
      ),
  },

  { label: "Minha conta", key: "MyAccount", route: "/MyAccount" },
];
