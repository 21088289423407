/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import {
  Col,
  Flex,
  Row,
  Space,
  Table,
  Typography,
  Modal,
  Form,
  Popconfirm,
  message,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import type { TableProps } from "antd";
import { ColCustom } from "./styles";
import CustomButton from "../../../../components/Buttom";
import CustomInput from "../../../../components/Input";
import { colors } from "../../../../theme/colors";
import PeopleRegistrationForm from "./PeopleRegistrationForm";
import { initialValues, IPropsValues, IDocument, IContact } from "./utils";
import { IPropsResponsePerson } from "../../../../services/registrations/types";
import {
  getPeople,
  deletePerson,
} from "../../../../services/registrations/index";

const People = () => {
  const isMobile = useMediaQuery({ maxWidth: 900 });
  const [data, setData] = useState<IPropsResponsePerson[]>([]);
  const [peopleData, setPeopleData] = useState<IPropsResponsePerson[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [formPerson, setFormPerson] = useState<IPropsValues>(initialValues);
  const [form] = Form.useForm<IPropsValues & { id?: string }>();
  const [isEditing, setIsEditing] = useState(false);
  const [currentItem, setCurrentItem] = useState<IPropsValues | null>(null);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    fetchPeopleData();
  }, [isOpen]);

  const fetchPeopleData = () => {
    setLoading(true);
    getPeople()
      .then((response) => {
        setData(response);
        setPeopleData(response);
      })
      .catch((error) => {
        console.error("Error fetching people data:", error);
        message.error("Erro ao buscar dados de pessoas.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSearch = (value: string) => {
    if (value.trim() === "") {
      setPeopleData(data);
      return;
    }
    const lowerCaseValue = value.toLowerCase();
    const filteredData = data.filter((person) => {
      return (
        person.tradeName?.toLowerCase().includes(lowerCaseValue) ||
        person.legalName?.toLowerCase().includes(lowerCaseValue) ||
        (person.documents &&
          person.documents.some((document) =>
            document.value?.toLowerCase().includes(lowerCaseValue)
          )) ||
        (person.contacts &&
          person.contacts.some((contact) =>
            contact.value?.toLowerCase().includes(lowerCaseValue)
          ))
      );
    });
    setPeopleData(filteredData);
  };

  const handleAdd = () => {
    setFormPerson(initialValues);
    setIsOpen(true);
  };

  const handleEdit = (person: IPropsResponsePerson) => {
    const personToEdit: IPropsValues & { id?: string } = {
      id: person.id,
      type: person.type,
      legalName: person.legalName || "",
      tradeName: person.tradeName || "",
      documents:
        person.documents?.map((doc) => ({
          id: doc.id,
          type: doc.type,
          value: doc.value || "",
        })) || [],
      contacts:
        person.contacts?.map((contact) => ({
          id: contact.id,
          type: contact.type,
          value: contact.value || "",
          isPrimary: contact.isPrimary || false,
        })) || [],
      addresses:
        person.addresses?.map((address) => ({
          id: address.id,
          type: address.type,
          postalCode: address.postalCode || "",
          ibgeCode: address.municipality?.ibgeCode || "",
          street: address.street || "",
          neighborhood: address.neighborhood || "",
          city: address.municipality?.name || "",
          uf: address.municipality?.stateCode || "",
          number: address.number || "",
          complement: address.complement || "",
        })) || [],
    };
    setFormPerson(personToEdit);
    setIsEditing(true);
    setIsOpen(true);
  };

  const handleDelete = async (person: IPropsResponsePerson) => {
    try {
      setLoading(true);
      await deletePerson(person.id);
      message.success("Pessoa deletada com sucesso!");
      fetchPeopleData();
    } catch (error) {
      if (error instanceof Error) {
        message.error("Erro ao deletar pessoa: " + error.message);
      } else {
        message.error("Erro ao deletar pessoa.");
      }
      console.error("Error deleting person:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    setIsOpen(false);
    setIsEditing(false);
    setCurrentItem(null);
    setFormPerson(initialValues);
  };

  const handleSubmit = (values: IPropsValues) => {
    setIsOpen(false);
    setFormPerson(formPerson);
    setIsEditing(false);
    fetchPeopleData();
  };

  const columns: TableProps<IPropsResponsePerson>["columns"] = [
    {
      title: "CNPJ/CPF",
      key: "document",
      render: (text: string, record: IPropsResponsePerson) => {
        const doc = record.documents?.find(
          (d) => d.type === "cpf" || d.type === "cnpj"
        );
        return <Typography.Text>{doc ? doc.value : ""}</Typography.Text>;
      },
    },
    {
      title: "Nome Fantasia",
      dataIndex: "tradeName",
      key: "tradeName",
      render: (text: string) => <Typography.Text>{text}</Typography.Text>,
    },
    {
      title: "Razão Social",
      dataIndex: "legalName",
      key: "legalName",
      render: (text: string) => <Typography.Text>{text}</Typography.Text>,
    },
    {
      title: "Telefone",
      key: "phone",
      render: (text: string, record: IPropsResponsePerson) => {
        const phone = record.contacts?.find(
          (c) => c.type === "landline" || c.type === "mobile"
        );
        return <Typography.Text>{phone ? phone.value : ""}</Typography.Text>;
      },
    },
    {
      title: "E-mail",
      key: "email",
      render: (text: string, record: IPropsResponsePerson) => {
        const email = record.contacts?.find((c) => c.type === "email");
        return <Typography.Text>{email ? email.value : ""}</Typography.Text>;
      },
    },
    {
      title: "Editar | Excluir",
      key: "action",
      align: "center",
      render: (record: IPropsResponsePerson) => (
        <Space size="middle">
          <EditOutlined
            style={{ color: colors.info, cursor: "pointer", fontSize: 18 }}
            onClick={() => handleEdit(record)}
          />
          <Popconfirm
            title="Tem certeza que deseja deletar este item?"
            onConfirm={() => handleDelete(record)}
            okText="Sim"
            cancelText="Não"
            placement="topRight"
          >
            <DeleteOutlined
              style={{ color: colors.error, cursor: "pointer", fontSize: 18 }}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Flex vertical>
      {/* Header */}
      <Typography.Title level={5} style={{ padding: 0 }}>
        Cadastro de Pessoas
      </Typography.Title>

      <Row
        align="middle"
        justify="space-between"
        style={{
          width: "100%",
          flexWrap: "nowrap",
          marginBottom: "12px",
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
        }}
      >
        <Col style={{ width: isMobile ? "100%" : "50%", marginBottom: "14px" }}>
          <CustomInput
            style={{ width: isMobile ? "100%" : "50%" }}
            title="Pesquisar"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (e.key === "Enter") {
                handleSearch(searchText);
              }
            }}
            addonAfter={
              <SearchOutlined
                style={{ cursor: "pointer", color: colors.info }}
                onClick={() => handleSearch(searchText)}
              />
            }
          />
        </Col>
        <CustomButton
          type="primary"
          onClick={() => handleAdd()}
          style={{ marginBottom: "8px", width: isMobile ? "100%" : "125px" }}
        >
          <PlusOutlined /> Criar novo
        </CustomButton>
      </Row>

      {/* Table */}
      <Table columns={columns} dataSource={peopleData} loading={loading} />

      {/* Modal */}
      <Modal
        open={isOpen}
        onCancel={() => setIsOpen(false)}
        footer={null}
        width={isMobile ? "100vw" : "70vw"}
        style={{ overflowY: "auto", padding: "12px" }}
        centered
      >
        <PeopleRegistrationForm
          person={formPerson}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          isEditing={isEditing}
        />
      </Modal>
    </Flex>
  );
};

export default People;
