import { ThemeProvider } from 'styled-components';

import ConfigProviderAntd from './theme/config/ProviderAntd';
import GlobalStyles from './theme/globalStyles';
import { theme } from './theme/theme';

import { AuthProvider } from './hooks/AuthContext';

import AppRoutes from './routes';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ConfigProviderAntd>
        <GlobalStyles />
        <AuthProvider>
          <AppRoutes />
        </AuthProvider>
      </ConfigProviderAntd>
    </ThemeProvider>
  );
}

export default App;
