import React from "react";
import { Flex, Select, Typography } from "antd";
import type { SelectProps as AntdSelectProps } from "antd";

interface CustomSelectProps extends AntdSelectProps {
  title?: string;
}

const CustomSelect: React.FC<CustomSelectProps> = ({
  title,
  ...props
}) => {
  return (
    <Flex vertical>
      {title && (
        <Typography.Text
          style={{
            marginBottom: 0,
            display: "block",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {title}
        </Typography.Text>
      )}
      <Select
        style={{ marginBottom: 5 }}
        {...props}
        size="middle"
      />
    </Flex>
  );
};

export default CustomSelect;
