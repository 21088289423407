import axios from "axios";
import { REACT_APP_API_URL } from "../config/constants";
import { getToken, removeToken } from "../services/utils/users";


const api = axios.create({
  baseURL: `${REACT_APP_API_URL}`,
});

api.interceptors.request.use((config) => {
  const newConfig = config;
  const token = getToken();
  if (token) {
    newConfig.headers.Authorization = `Bearer ${token}`;
  }
  return newConfig;
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response } = error;
    if (response && response.status === 401) {
      removeToken();
      window.location.reload();
    }

    return Promise.reject(error);
  }
);

export default api;