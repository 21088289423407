import styled from 'styled-components';
import { Col } from 'antd';

export const ColCustom = styled(Col)<{ isRigth: boolean, isMobile: boolean }>`
  width: 100%;
  background-color: #fff;
  position: relative;
  display: flex; /* Adiciona Flexbox */
  flex-direction: column; /* Organiza elementos na vertical */

  border-top-right-radius: ${(props) =>
    props.isMobile ? 0 : props.isRigth ? "20px" : 0};
  border-bottom-right-radius: ${(props) =>
    props.isMobile ? 0 : props.isRigth ? "20px" : 0};
  border-top-left-radius: ${(props) =>
    props.isMobile ? 0 : !props.isRigth ? "20px" : 0};
  border-bottom-left-radius: ${(props) =>
    props.isMobile ? 0 : !props.isRigth ? "20px" : 0};

  padding: ${(props) =>
    props.isMobile
      ? "14px"
      : !props.isRigth
      ? "99px 12px 24px 24px"
      : "99px 24px 24px 12px"};
  
  /* Adiciona espaçamento automático para empurrar o botão ao final */
  & > .button-col {
    margin-top: auto;
  }
`
