/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Flex, Image, message, Row, Typography } from "antd";
import { useMediaQuery } from "react-responsive";
import * as Yup from "yup";

import { ColCustom } from "./styles";
import ImageLogo from "../../../assets/images/logo.svg";
import CustomInput from "../../../components/Input";
import CustomSelect from "../../../components/Select";
import CustomButton from "../../../components/Buttom";
import { useAuth } from "../../../hooks/AuthContext";
import {
  EyeInvisibleOutlined,
  EyeOutlined,
  LoadingOutlined,
  WhatsAppOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import {
  formatCpfCnpj,
  formatCurrency,
  formatPhone,
  formatPostalCode,
  formatTextOnlyUpperCase,
} from "../../../utils/masks";
import { apiViaCep, IReturnApiCep } from "../../../services/viaCep";
import { useState } from "react";
import {
  initialValues,
  IPropsValues,
  RegisterSchema,
  transformToApiPayload,
} from "./utils";
import { colors } from "../../../theme/colors";

const Register = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const isMobile = useMediaQuery({ maxWidth: 900 });
  const { loading } = useAuth();
  const [lockPassword, setLockPassword] = useState<boolean>(true);
  const [values, setValues] = useState<IPropsValues>(initialValues);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const handleChange = (key: string, value: string) => {
    setValues((prev) => ({ ...prev, [key]: value }));
  };

  const handleSubmit = async () => {
    try {
      await RegisterSchema.validate(values, { abortEarly: false });
      const valuesAPI = transformToApiPayload(values);
      console.log(valuesAPI);
      messageApi.success("Usuário cadastrado com sucesso!");
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const validationErrors: Record<string, string> = {};
        error.inner.forEach((err) => {
          if (err.path) {
            validationErrors[err.path] = err.message;
          }
        });
        setErrors(validationErrors);
      } 
      messageApi.error("Erro ao cadastrar usuário.");
    }
  };

  const handlePostalCodeChange = async (value: string) => {
    console.log(value);
    try {
      const { data } = await apiViaCep.get<IReturnApiCep>(`${value}/json/`);
      handleChange("neighborhood", data.bairro);
      handleChange("ibgeCode", data.ibge);
      handleChange("city", data.localidade);
      handleChange("street", data.logradouro);
      handleChange("uf", data.uf);
    } catch (error) {
      console.error(error);
      messageApi.error("Erro ao cadastrar usuário.");
    }
  };

  return (
    <Flex
      style={{
        padding: isMobile ? 0 : 24,
        flexDirection: isMobile ? "column" : "row",
      }}
    >
      {contextHolder}
      <ColCustom isMobile={isMobile} isRigth={false}>
        <Row
          align="middle"
          style={{
            marginBottom: "20px",
            position: !isMobile ? "absolute" : "relative",
            top: !isMobile ? 24 : 0,
            left: !isMobile ? 24 : 0,
          }}
        >
          <Image style={{ width: "300px" }} src={ImageLogo} preview={false} />
        </Row>
        {/* CADASTRO */}
        <Typography.Title level={3} style={{ margin: "0 0 5px" }}>
          Cadastro
        </Typography.Title>
        <CustomInput
          title="CNPJ/CPF"
          maxLength={18}
          placeholder={
            errors.document ? "Campo Obrigatório" : "Informe seu CNPJ/CPF"
          }
          status={errors.document && "error"}
          icon={errors.document && <InfoCircleOutlined />}
          value={formatCpfCnpj(values.document)}
          onChange={(e) => handleChange("document", e.target.value)}
        />
        <CustomInput
          title="Nome de Identificação"
          value={values.tradeName}
          onChange={(e) => handleChange("tradeName", e.target.value)}
          placeholder={
            errors.tradeName
              ? "Campo Obrigatório"
              : "Informe seu Nome de Identificação"
          }
          status={errors.tradeName && "error"}
          icon={errors.tradeName && <InfoCircleOutlined />}
        />
        <CustomInput
          title="Razão Social/Nome"
          value={values.legalName}
          onChange={(e) => handleChange("legalName", e.target.value)}
          placeholder={
            errors.legalName
              ? "Campo Obrigatório"
              : "Informe seu Razão Social/Nome"
          }
          status={errors.legalName && "error"}
          icon={errors.legalName && <InfoCircleOutlined />}
        />

        {/* CONTATO */}
        <Typography.Title level={3} style={{ margin: "10px 0 5px" }}>
          Contato
        </Typography.Title>
        <Row
          style={{
            gap: isMobile ? 0 : "10px",
            flexFlow: "nowrap",
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          <Col style={{ width: !isMobile ? "65%" : "100%" }}>
            <CustomInput
              title="Email"
              value={values.email}
              onChange={(e) => handleChange("email", e.target.value)}
              placeholder={
                errors.email ? "Campo Obrigatório" : "Informe seu Email"
              }
              status={errors.email && "error"}
              icon={errors.email && <InfoCircleOutlined />}
            />
          </Col>
          <Col style={{ width: !isMobile ? "35%" : "100%" }}>
            <CustomInput
              title="Telefone"
              maxLength={15}
              value={formatPhone(values.phone)}
              onChange={(e) => handleChange("phone", e.target.value)}
              placeholder={errors.phone ? "Campo Obrigatório" : "(99)9999-9999"}
              status={errors.phone && "error"}
              icon={errors.phone && <InfoCircleOutlined />}
            />
          </Col>
        </Row>
        <CustomInput
          title="Nome do usuário"
          value={values.username}
          onChange={(e) => handleChange("username", e.target.value)}
          placeholder={
            errors.username
              ? "Campo Obrigatório"
              : "Informe o nome para o usuário"
          }
          status={errors.username && "error"}
          icon={errors.username && <InfoCircleOutlined />}
        />
        <Row style={{ gap: 10, flexFlow: "nowrap" }}>
          <Col style={{ width: "50%" }}>
            <CustomInput
              title="Senha"
              value={values.password}
              onChange={(e) => handleChange("password", e.target.value)}
              type={lockPassword ? "password" : "text"}
              placeholder={errors.password ? errors.password : "Crie sua Senha"}
              status={errors.password && "error"}
              icon={errors.password && <InfoCircleOutlined />}
            />
            {lockPassword ? (
              <EyeOutlined
                style={{
                  fontSize: 20,
                  color: colors.iconPrimary,
                  position: "absolute",
                  right: 10,
                  top: 28,
                }}
                onClick={() => setLockPassword(!lockPassword)}
              />
            ) : (
              <EyeInvisibleOutlined
                style={{
                  fontSize: 20,
                  color: colors.iconPrimary,
                  position: "absolute",
                  right: 10,
                  top: 28,
                }}
                onClick={() => setLockPassword(!lockPassword)}
              />
            )}
          </Col>
          <Col style={{ width: "50%" }}>
            <CustomInput
              title="Confirme a senha"
              value={values.confirmPassword}
              onChange={(e) => handleChange("confirmPassword", e.target.value)}
              type={lockPassword ? "password" : "text"}
              placeholder={
                errors.confirmPassword
                  ? errors.confirmPassword
                  : "Confirme a senha"
              }
              status={errors.confirmPassword && "error"}
              icon={errors.confirmPassword && <InfoCircleOutlined />}
            />
            {lockPassword ? (
              <EyeOutlined
                style={{
                  fontSize: 20,
                  color: colors.iconPrimary,
                  position: "absolute",
                  right: 10,
                  top: 28,
                }}
                onClick={() => setLockPassword(!lockPassword)}
              />
            ) : (
              <EyeInvisibleOutlined
                style={{
                  fontSize: 20,
                  color: colors.iconPrimary,
                  position: "absolute",
                  right: 10,
                  top: 28,
                }}
                onClick={() => setLockPassword(!lockPassword)}
              />
            )}
          </Col>
        </Row>

        {/* ENDEREÇO */}
        <Typography.Title level={3} style={{ margin: "10px 0 5px" }}>
          Endereço
        </Typography.Title>
        <Row
          style={{
            gap: isMobile ? 0 : "10px",
            flexFlow: "nowrap",
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          <Row style={{ gap: 10, flexFlow: "nowrap" }}>
            <Col style={{ width: "70%" }}>
              <CustomInput
                title="CEP"
                value={formatPostalCode(values.postalCode)}
                onChange={(e) => handleChange("postalCode", e.target.value)}
                maxLength={9}
                onBlur={(e) => handlePostalCodeChange(e.target.value)}
                placeholder={
                  errors.postalCode ? "Campo Obrigatório" : "00000-000"
                }
                status={errors.postalCode && "error"}
                icon={errors.postalCode && <InfoCircleOutlined />}
              />
            </Col>
            <Col style={{ width: "30%" }}>
              <CustomInput
                title="UF"
                maxLength={2}
                value={formatTextOnlyUpperCase(values.uf)}
                onChange={(e) => handleChange("uf", e.target.value)}
                placeholder={errors.uf ? "Campo Obrigatório" : "UF"}
                status={errors.uf && "error"}
                icon={errors.uf && <InfoCircleOutlined />}
              />
            </Col>
          </Row>
          <Col style={{ width: "100%" }}>
            <CustomInput
              title="Cidade"
              value={values.city}
              onChange={(e) => handleChange("city", e.target.value)}
              placeholder={
                errors.city ? "Campo Obrigatório" : "Informe a Cidade"
              }
              status={errors.city && "error"}
              icon={errors.city && <InfoCircleOutlined />}
            />
          </Col>
        </Row>
        <Row style={{ gap: 10, flexFlow: "nowrap" }}>
          <Col style={{ width: "70%" }}>
            <CustomInput
              title="Endereço"
              value={values.street}
              onChange={(e) => handleChange("street", e.target.value)}
              placeholder={
                errors.street ? "Campo Obrigatório" : "Informe seu endereço"
              }
              status={errors.street && "error"}
              icon={errors.street && <InfoCircleOutlined />}
            />
          </Col>
          <Col style={{ width: "30%" }}>
            <CustomInput
              title="Código IBGE"
              value={values.ibgeCode}
              onChange={(e) => handleChange("ibgeCode", e.target.value)}
              placeholder={
                errors.ibgeCode ? "Campo Obrigatório" : "Informe o Código IBGE"
              }
              status={errors.ibgeCode && "error"}
              icon={errors.ibgeCode && <InfoCircleOutlined />}
            />
          </Col>
        </Row>
        <Row style={{ gap: 10, flexFlow: "nowrap" }}>
          <Col style={{ width: "100%" }}>
            <CustomInput
              title="Bairro"
              value={values.neighborhood}
              onChange={(e) => handleChange("neighborhood", e.target.value)}
              placeholder={
                errors.neighborhood ? "Campo Obrigatório" : "Informe seu bairro"
              }
              status={errors.neighborhood && "error"}
              icon={errors.neighborhood && <InfoCircleOutlined />}
            />
          </Col>
          <Col style={{ width: "30%" }}>
            <CustomInput
              title="Número"
              value={values.numberAddress}
              onChange={(e) => handleChange("numberAddress", e.target.value)}
              placeholder={
                errors.numberAddress ? "Campo Obrigatório" : "Número"
              }
              status={errors.numberAddress && "error"}
              icon={errors.numberAddress && <InfoCircleOutlined />}
            />
          </Col>
        </Row>
        <CustomInput
          title="Complemento"
          placeholder="Informe um complemento"
          value={values.complement}
          onChange={(e) => handleChange("complement", e.target.value)}
        />
      </ColCustom>
      <ColCustom isMobile={isMobile} isRigth>
        <Typography.Title level={3} style={{ margin: "0 0 5px" }}>
          Informações Comerciais
        </Typography.Title>
        <Row
          style={{
            gap: isMobile ? 0 : "10px",
            flexFlow: "nowrap",
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          <Col style={{ width: isMobile ? "100%" : "50%" }}>
            <CustomSelect
              title="Categoria"
              value={values.category_id}
              placeholder={
                errors.category_id
                  ? "Campo Obrigatório"
                  : "Selecione a categoria"
              }
              status={errors.category_id && "error"}
              onChange={(e) => handleChange("category_id", e)}
            >
              <option value="red">Red</option>
              <option value="green">Green</option>
              <option value="blue">Blue</option>
            </CustomSelect>
          </Col>
          <Col style={{ width: isMobile ? "100%" : "50%" }}>
            <CustomSelect
              title="Brandeira"
              value={values.brand}
              placeholder={
                errors.brand ? "Campo Obrigatório" : "Selecione a bandeira"
              }
              status={errors.brand && "error"}
              onChange={(e) => handleChange("brand", e)}
            >
              <option value="red">Red</option>
              <option value="green">Green</option>
              <option value="blue">Blue</option>
            </CustomSelect>
          </Col>
        </Row>
        <CustomInput
          title="Código ANP"
          placeholder="Informe o código ANP"
          value={values.anp_code}
          onChange={(e) => handleChange("anp_code", e.target.value)}
        />
        <CustomSelect
          title="Base de operação"
          placeholder="Selecione a Base de operação"
          value={values.operation_base}
          onChange={(e) => handleChange("operation_base", e)}
        >
          <option value="red">Red</option>
          <option value="green">Green</option>
          <option value="blue">Blue</option>
        </CustomSelect>
        <Row
          style={{
            gap: isMobile ? 0 : "10px",
            flexFlow: "nowrap",
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          <Col style={{ width: isMobile ? "100%" : "50%" }}>
            <CustomInput
              title="Volume de venda ou consumo"
              placeholder="Informe o volume"
              value={values.sales_volume}
              onChange={(e) =>
                handleChange("sales_volume", formatCurrency(e.target.value))
              }
            />
          </Col>
          <Col style={{ width: isMobile ? "100%" : "50%" }}>
            <CustomInput
              title="Faturamento de venda de combustivel"
              placeholder="Informe o valor médio"
              value={values.average_revenue}
              onChange={(e) =>
                handleChange("average_revenue", formatCurrency(e.target.value))
              }
            />
          </Col>
        </Row>
        <Row
          style={{
            gap: isMobile ? 0 : "10px",
            flexFlow: "nowrap",
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          <Col style={{ width: isMobile ? "100%" : "50%" }}>
            <CustomInput
              title="Faturamento de conveniência"
              placeholder="Informe o valor médio"
              value={values.convenience_revenue}
              onChange={(e) =>
                handleChange(
                  "convenience_revenue",
                  formatCurrency(e.target.value)
                )
              }
            />
          </Col>
          <Col style={{ width: isMobile ? "100%" : "50%" }}>
            <CustomInput
              title="Faturamento de troca de óleo"
              placeholder="Informe o valor médio"
              value={values.oil_change_revenue}
              onChange={(e) =>
                handleChange(
                  "oil_change_revenue",
                  formatCurrency(e.target.value)
                )
              }
            />
          </Col>
        </Row>
        <Col style={{ width: isMobile ? "100%" : "50%" }}>
          <CustomInput
            title="Faturamento de pista"
            placeholder="Informe o valor médio"
            value={values.fuel_pump_revenue}
            onChange={(e) =>
              handleChange("fuel_pump_revenue", formatCurrency(e.target.value))
            }
          />
        </Col>
        <CustomButton
          type="default"
          size="large"
          style={{
            width: "100%",
            marginTop: 10,
          }}
        >
          Selecione um plano
        </CustomButton>
        <Col
          className="button-col"
          style={{ marginTop: isMobile ? "10px" : "auto" }}
        >
          <CustomButton
            type="primary"
            size="large"
            style={{
              width: "100%",
              margin: "10px 0",
              backgroundColor: "#026D06",
            }}
          >
            Fale conosco <WhatsAppOutlined style={{ fontSize: 20 }} />
          </CustomButton>
          <CustomButton
            type="primary"
            size="large"
            style={{ width: "100%", marginTop: 16 }}
            disabled={loading}
            onClick={handleSubmit}
          >
            {loading ? (
              <LoadingOutlined style={{ fontSize: 20 }} />
            ) : (
              "Registrar"
            )}
          </CustomButton>
        </Col>
      </ColCustom>
    </Flex>
  );
};

export default Register;
